.update-panels input{
    padding: 35px;
    background-color: #FFF;
    margin: 35px;
    border-radius: 15px;
    width: 100%;
}
.update-panels{
    display: flex;
    flex-direction: column;
    padding: 5%;


}
