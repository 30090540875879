.comments-comp{
    display: flex;
    flex-direction: column;
    margin: 70px 0;
    width: 100%;
    background-color:#FAFAFA;
    padding:50px 24%;
}
.comments-comp h2{
    width: 100%;
    text-align: left;
}

.comment-card{
    display: flex;
    background-color: #FFF;
    border-radius: 15px;
    padding: 20px;
}
.comment-card .quantity-card{
    background-color: #F8F8F8;
    display: flex;
    flex-direction: column;
    color: #0B91C5;
    height: 100%;
    padding: 15px;
    border-radius: 15px;
    margin: 15px;
    margin-right: 35px;
}
.quantity-card span{
    font-weight: 600;
    margin: 15px 0;
}
.comment-card-content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.prof{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-top: 10px;
}
.reply-btn{
    display: flex;
    align-items: center;
    color: #0B91C5;
    font-weight: 600;
}
.reply-btn span{
    margin-left: 10px;
}
.prof h3{
    font-weight: 500;
    margin-left: 30px;
    margin-right: 40px;
}
.comment-card-flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-right: 10px;
}

.comment-content p{
    text-align: left;

}



