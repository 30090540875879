.about-header h1{
    font-size: 2.5vw;
    color: #FFF;
}
.about-header{
    position: relative;
    height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
@media only screen and (max-width: 1000px) {
    .about-header h1{
        font-size: 20px;
    }
    .about-header img{
        width: 75%;
    }
    
}
