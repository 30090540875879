
@media only screen and (max-width: 1000px) {
    .test-2341 .wrapper-white{
        padding-left: 5px !important;
        padding-right: 5px !important;
    }

    .test-2341 .caravans-buttons{
        display: flex;
        flex-direction: column !important;
        align-items: center;
    }
    .test-2341 .caravans-buttons button{
        font-size: 3.6vw;
        margin: 0;
        margin-bottom: 10px;
        width: 50%;
    
    }
    .caravans-header{
        height: 40vh;
    }
    .caravans-header h1{
        margin-bottom: 80px !important;
        font-size: 7.2vw;
    }
}