.banner{
    background: #0EA3CA;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 300px;
}

.banner .part ul li{
    color: #FFF;
}
@media only screen and (max-width: 1000px) {
    .banner .part ul li{
        font-size: 3vw;
    }
}