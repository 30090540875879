@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);

@-webkit-keyframes fade-right {
    0% {
      opacity: 0;
      -webkit-transform: translateX(-3rem);
              transform: translateX(-3rem);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
}
@keyframes fade-right {
    0% {
      opacity: 0;
      -webkit-transform: translateX(-3rem);
              transform: translateX(-3rem);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
}
@-webkit-keyframes fade-left {
    0% {
      opacity: 0;
      -webkit-transform: translateX(3rem);
              transform: translateX(3rem);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
}
@keyframes fade-left {
    0% {
      opacity: 0;
      -webkit-transform: translateX(3rem);
              transform: translateX(3rem);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
}
@-webkit-keyframes fade-down {
    0% {
      opacity: 0;
      -webkit-transform: translateY(-3rem);
              transform: translateY(-3rem);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
}
@keyframes fade-down {
    0% {
      opacity: 0;
      -webkit-transform: translateY(-3rem);
              transform: translateY(-3rem);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
}
@-webkit-keyframes fade-up {
    0% {
      opacity: 0;
      -webkit-transform: translateY(3rem);
              transform: translateY(3rem);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
}
@keyframes fade-up {
    0% {
      opacity: 0;
      -webkit-transform: translateY(3rem);
              transform: translateY(3rem);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
}

*{
    font-family: 'Poppins', sans-serif;
}

.wrapper{

    position: relative;
    padding: 3% 7%;
    border-radius: 36px !important;
    background-color: #fff;
    width: 100%;
}


html{
    max-width: 100vw !important;
}
@media only screen and (max-width: 1550px) {
    .left-menu ul li{
        font-size: smaller;
        margin-left: 15px;
    }
    .navbar .left-menu img{
        width: 95px;
    } 
    
.wrapper{

  z-index: 1;
}
} 

.background, .text{
    position: relative;
    z-index: 0;
}

.main{
    height: auto;
}
.left-plant, .circle-bg, .cloud, .right-plant, .playButton{
    position: absolute;
}
img.caravan{
    width: 100%;
    height: auto;
}

.text-group{
    width: 479px;
}

.left-plant{
    top: 115px;
    left: 10%;
}


.cloud{
    top: 85px;
    left: 55%;
}

.right-plant{
    top: 290px;
    left: 85%;
}


/* 
.playButton{
    left: 30%;
    top: 385px;
} */


#title-1{
    line-height: 120.5%;
    font-size: 4vw;
    font-weight: 500;
    color: #127BA8;
}

#title-2{
    font-size: 2vw;
    font-weight: 500;
    color: #127BA8;
}

.text-group p{
    font-weight: 400;
    font-size: 19px;
}

.group{
    position: relative;
    z-index: 1;
    padding: 90px 10%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media only screen and (max-width: 1100px) {
.group{
    flex-direction: column;
    padding: 30px 10%;
} 

.group .text-group{
    margin-top: 45px;
    width: 600px;   
}
img.cloud{
    display: none;
}
 .group .text{
    text-align: left;
    width: 100%;
 }

 .group .text h1#title-1{
    font-size: 8vw;
 }
 .group .text h1#title-2{
    font-size: 7vw;
    margin-bottom: 10px;
 }
 .group .text p{
    font-size: 2vw;
 }
}

@media only screen and (max-width: 1000px) {
    .group .text p{
        font-size: 4vw;
        width: 60vw !important;
    }
    .main .background{
        display: none !important;
    }
}

.home-header{
    background: no-repeat linear-gradient(180.3deg, #006E9D 0.26%, #BBEBFF 79%);
    height: 600px;
    position: relative;
}


.title-sec{
    width: 100%;
    position: relative;
    z-index: 2;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #FFF;
    font-size: x-large;
    padding: 7rem;
    
}
.te{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 4;
}
.title-sec h1{
    -webkit-animation: fade-down 0.7s;
            animation: fade-down 0.7s;
}
img.title-sec-bg{
    padding:0 80px;
    width: 100%;
    position: absolute;
    bottom: 2px;
}
.button3, .button4{
    width: 230px;
}
.button3{
    background-color: #FFF;
    color: #006E9D;
    font-weight: 500;
}
.button4{
    background-color: transparent;
    color: #fff;
    font-weight: 500;
}
.buttons{
    margin-top: 32px;
    display: flex;
}
.divv{
    position: relative;
    z-index: 4;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 55%;
}
.searchBar{
    margin-top: 40px;
    background: #FFFFFF;
    box-shadow: 0px 4px 37px rgba(13, 160, 201, 0.54);
    border-radius: 100px;
    display: flex;
    align-items: center;
    z-index: 10;
    height: 76px;
    padding-left: 40px;
}
.searchBar input{
    background-color: #FFF;
    border: none;
    font-size: 0.8vw;
    outline: none;
}
input#mainInput{
    width: 40%;
}
.searchBar input:active{
    border: none;
    outline: none;
}
.button5{
    background: linear-gradient(94.76deg, #117AA7 9.47%, #0DA2C9 73.37%);
    border-radius: 110px !important;
    color: #FFFFFF;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    height: 65px !important; 
    width: 140px;
    margin-right: 5px;
}

.dateInput{
    display: flex;
    align-items: center;
    margin-left: 20px;
    max-width: 170px;
}
    
.dateInput img{
    margin-right: 15px;
}
button{
    cursor: pointer;
}
.vinn{
    margin-left: 15px;
}
.vinn button{
    height: 65px;
    margin-right: 5px !important;
}

span#line{
    border: 1px solid #F0F0F0;
    height: 70%;
    margin-left: 10px;
}
/* img.title-sec-bg-mob{
    display: none;
 } */
 img.title-sec-bg-mob{
    width: 60%;
    position: absolute;
    bottom: -70px;
    left: 0;
    display: none;
}
img.title-sec-bg-mob-cloud{
    width: 20%;
    position: absolute;
    top: 10px;
    left: 20px;
    display: none;
}
.inputs-mobile{
    display: none !important; 
}
img.title-sec-bg1{
    display: none;
}
@media only screen and (max-width: 500px) {
 .home-header .title-sec .te h1{
    font-size: 9vw;
 }
.title-sec{
    padding: 3rem 0;
}
 .title-sec .buttons button{
    font-size: 4vw;
    width: 70%;
    margin-bottom: 10px;
 }
 .home-header{
    height: 750px;
 }
 
 .title-sec .buttons{
    flex-direction: column-reverse;
 }
 .vinn{
    margin-left: 5px;
}
img.title-sec-bg1{
    z-index: -3;
    display: block;
    position: absolute;
}
img.title-sec-bg1.down{
    bottom: 0vh;
}
 .searchBar{ 
    padding: 0;
    height: auto;
    background-color: transparent;
    box-shadow: 0px 0px 0px transparent !important;
    flex-direction: column;
}
.searchBar .vinn button{
    height: auto;
    font-size: 6vw;
    width: 80vw;
    border: 0px;
    margin-bottom: 10px;
}
 .dateInput img{
    width: 15% !important;
    margin: 0;
 }
 .mbo{
    background-color: #FFF;
    border-radius: 80px;
    margin: 15px 35px;
 }
 .dateInput{
    margin: 5px;
    background-color: #FFF;
    border-radius: 60px;
 }
 .dateInput input, input#mainInput{
    font-size: 3.5vw;
    border-radius: 80px;
    padding: 15px;
    width: 80vw;
 }
 .mbo{
    display: flex;
 }
 img.title-sec-bg{
    display: none;
 }
 img.title-sec-bg-mob, img.title-sec-bg-mob-cloud{
    display: block;
 }
 img.title-sec-bg-mob-cloud{
    width: 15%;
 }
 img.title-sec-bg-mob{
    width: 40%;
    bottom: -30px;
}
.inputs-mobile{
    display: block !important;
}
.inputs-desktop{
    display: none !important;
}
img.title-sec-bg1.down{
    display: block;
}
.searchBar input{
    font-size: 3vw;
}
.DatePicker{
    border-radius: 0px !important;
}
.mob-input{
    padding: 5px;
    background-color: #fff;
    border-radius: 20px;
    padding-bottom: 5px;
}
.te h1{
    width: 60%;
}
} 
/* @media only screen and (max-width: 500px) {
    img.title-sec-bg-mob{
        width: 50%;
        bottom: -70px;
    }
} */

@media only screen and (min-width: 1000px) {
    img.title-sec-bg1.down{
        display: none;
    }
    select#select-cont{
        width: 100%;
    }
}
/* NAVBAR */
.navbar{
    display: flex;
    align-items: center !important;
    justify-content: space-between;
    padding: 20px 8%;
    background-color: transparent;
}

.left-menu{
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.left-menu ul{
    display: flex;
}

.left-menu ul li{
    font-size: 0.9vw;
    cursor: pointer;
    margin-left: 25px;
    list-style-type: none;
    font-weight: 500;
}

.hamburger-menu{
    display: none;
}

.right-menu{
    display: flex;
}

.right-menu button, .title-sec button{
    border: 2px solid #FFFFFF;
    border-radius: 30px;
    
}
.navbar img.logoCaravinn, .navbar-white img.logoCaravinn{
    width: 170px;
}
@media only screen and (max-width: 1000px) {
    .left-menu ul{
        display: none;
    }
    .right-menu .after-logged,.right-menu .before-logged{
        display: none;
    }
    .hamburger-menu{
        display: block;
    }
    .navbar{
        flex-direction: row-reverse;
    }
    .navbar img.logoCaravinn{
        left: 30vw;
        top: 17px;
        width: 40%;
        position: absolute;
    }
}












.app__navbar-smallscreen_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 90%;
    height: 100vh;
    background: #FFF;
   
    transition: 2s;

    flex-direction: column;
    z-index: 20;
}

.app__navbar-smallscreen_overlay .overlay__close {
    font-size: 27px;
    color: #0b90c5;
    cursor: pointer;

    position:absolute;
    top: 20px;
    right: 30px;
}
.fl-ham{    display: flex;
    justify-content: space-between;}
.app__navbar-smallscreen_links ul hr{
    border: 1px solid #F2F2F2;
}
.app__navbar-smallscreen_links ul li {
    margin: 2rem;
    cursor: pointer;
    color: #0b90c5;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000000;

}
.loginButton, .karavanAddButton{
    display: flex;
    justify-content: center;
    
}
.karavanAddButton{
    align-items: center;
}
.loginButton{
    height: 250px;
}
.loginButton button{
    margin-top: 50px;
    color: #1E98C7;
    background-color: transparent;
    border: 1px solid #EAEAEA;
}
.karavanAddButton button{
    background: linear-gradient(94.76deg, #117AA7 9.47%, #0DA2C9 73.37%);
    color: #FFF;
}
.loginButton button, .karavanAddButton button{
    padding: 10px 30px;
    width: 200px;
    border-radius: 60px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
}
.app__navbar-smallscreen_links ul li:hover {
    color: var(--color-black);
}
.app__navbar-smallscreen_links {
    list-style: none;
    margin-top: 70px;
}






.buttons-2{
    padding: 0 5%;
    display: flex;
    justify-content: space-between;
    align-items: center; 
}
.cards{
    width: 100%;
    margin-top: 45px;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
}

.left-buttons, .right-buttons{
    display: flex;
}


button.btn-left,button.btn-right{
    color: #0DA0C9;
    border: 1px solid #EEEEEE;
    border-radius: 100%;
    background-color: transparent;
    width: 50px;
    height: 50px;
    margin-right: 10px;
    align-items: center;
}
.buttons-2 .left-buttons button{
    border-radius: 30px;
}

@media only screen and (max-width: 1000px) {
    .buttons-2{
        align-items: flex-start; 
    }
    .buttons-2 .left-buttons{
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
    }
    .buttons-2 .left-buttons button{
        font-size: .8rem;
        margin-bottom: 5px !important;
        width: 42%;
        margin: 10px !important;
    }
    .buttons-2 .right-buttons button{
        font-size: .7rem;
    }
    .left-buttons button, .right-buttons button{
        margin: 0 !important;
    }
    #caravan-card-mobile{
        padding-top: 10px;
    }
    .right-buttons{
        display: none;
    }
}


body, html {
    width: 100vw;
    min-width: 100vw;
    max-width: 100vw;
   
    overflow-x: hidden;
    overflow-y: auto;
}
.card{
    position: relative;
    box-shadow: -5px 5px 19px 5px rgba(0, 0, 0, 0.09);
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    margin: 8px;
    height: 100%;
    width: 350px;
}
.card img{
   margin: 7px;
   object-fit: cover;
}
.card h3{
    padding: 20px;
}
span#line-hr{
    border: 1px solid #EBEBEB ;
    width: 90%;
}
.location{
    margin-left: 10px;
    display: flex;
    align-items: center;
    padding: 15px;
    color: #196CA5;
    font-weight: 500;
}
.location i{
    margin-right: 10px;
    color: #C5C5C5;
}
#caravan-card-mobile img.cardImg{
    height: 220px;
    width: 330px;
    border-radius: 15px;
    object-fit: cover;
}
.icons{
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding: 25px 10px;
}
.icon{
    display: flex;
    align-items: center;
    color: #196CA5;
    font-weight: 500;
}
.icon img{
    margin-right: 15px;
}
.card hr{
    width: 89%;
    margin: auto;
    height: 1px;
    border: 0;
    background-color: #EBEBEB;
}

.like-button {
    position: absolute;
    z-index: 2;
    top: 25px;
    right: 29px;
    color: red;
}
.promoted{
    position: absolute;
}
.mCards{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
@media only screen and (max-width: 1000px) {
  .card{
        width: 200px;
        display: inline-block;
    }
    
    .card-test{
        margin: 10px 1px;
    }
    .icons{
        display: none !important;
    }
    .card img{
        margin: 0px;
    }
    #caravan-card-mobile img.cardImg{
        height: 108px;
        width: 90%;
        border-radius: 15px;
    } 
    .wrapper    {
        padding: 0;
    }
    .mCards{
        align-items: stretch;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        justify-content: flex-start;
    }



}

.phoneSection img#img{
    position: absolute;
    z-index: 0;
}
img#mockup{
    width: 450px;
}
.content{
    position: relative;
    z-index: 2;
    padding: 175px 180px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.content .left-menu{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.phoneSection .content .left-menu ul li{
    font-size: 1vw;
    width: auto;
    margin: 0;
    margin-right: 35px;
    color: #196CA5;
    padding: 6px;
    width: auto;
    cursor: pointer;
}
.phoneSection .content .left-menu ul li.active{
    background: linear-gradient(93.81deg, #117AA7 14.63%, #0DA2C9 80.39%);
    border-radius: 80px;
    color: #FFF;
    padding: 8px;
    
}
.phoneSection .content .left-menu ul{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 55px;
}
.phoneSection .content .left-menu p{
    margin-top: 25px;
    width: 550px;
    color: #3D434C;
    font-weight: 400;
}
.content .left-menu h1{
line-height: 120.5%;
    font-size: 3vw;
}

.phoneSection .content .right-menu img.phone{
    position: relative;
    width: 100%;
    height: auto;
}
.phoneSection .content .right-menu{
    position: relative;
}

.leftArrow{
    width: 15%;
    height: auto;
    cursor: pointer;
    position: absolute;
    bottom: 50%;
}
.rightArrow{
    width: 15%;
    height: auto;
    cursor: pointer;
    position: absolute;
    bottom: 50%;
    right: 19%;
}

.leftPlant{
    width: 10%;
    height: auto;
    position: absolute;
    top: 600px;
    left: 6%;
}
.rightPlant{
    width: 10%;
    height: auto;
    position: absolute;
    top: 500px;
    right: 10%;
    z-index: 2;
}

@media only screen and (max-width: 1000px) {
    .phoneSection .content .left-menu ul li{
        font-size: .7rem !important;
        margin: 5px;
    }
    .phoneSection .content{
        width: 100%;
        padding: 30px 0;
        flex-direction: column-reverse;
        text-align: center;
    }
    .phoneSection .content .left-menu ul{
        flex-direction: row;
        -webkit-overflow-scrolling: touch;
        overflow-y: hidden;
        white-space: nowrap;
        width: auto;
        margin: 0;
    }
    .left-menu h1, p{
   
        width: 100% !important;
        text-align: start;
    }
    .left-menu p{
        margin-top: 10px !important;
        padding: 0px 5%;
    }
    .phoneSection .content .left-menu h1{
       font-size: 9vw !important;
       text-align: left;
        padding: 0px 5%;
    }
    .phoneSection .right-menu{
        margin-top: 35px;
        width: 50%;
    }
}

.karavanKirala img#img{
    position: absolute;
    z-index: 0;
}
.left-menu img#mockup{
    width: 80%;
    height: auto;
}
.karavanKirala .content{
    padding: 120px 150px;
    background:no-repeat url(/static/media/background.befc1542.svg);
}
.content .right-menu{
    display: flex;
    flex-direction: column;
    color: #FFF;
}


.karavanKirala .right-menu h1{
    font-size: 3vw;
    font-weight: 700;
    margin-bottom: 15px;
    width: 100%;
}
.karavanKirala .item{
    display: flex;
    align-items: center;
    margin: 15px;
    margin-left: 0;
}
span#circle{
    border: 1px solid #FFF;
    width: 10px;
    height: 10px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    border-radius: 100%;    
    margin: 10px;
    margin-left: 0;
}
.text h3{
    font-weight: 500;
}
.text p{
    font-size: .9vw;
}

.ed{
    margin-top: 15px;
    margin-right: 0;
}

@media only screen and (max-width: 1000px) {
    .karavanKirala .content{
        width: 100% !important;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0;
        padding-top: 120px;
        padding-bottom: 30px;
    }
    .karavanKirala .content .left-menu, .karavanKirala .content .right-menu{
        align-items: center;
        justify-content: center;
    }
    .karavanKirala .content .left-menu img#mockup{
        width: 50% !important;
    }
    .karavanKirala .content .right-menu h1{
        text-align: center;
        font-size: 5vw;
        margin-top: 25px;
    }
    .karavanKirala .content .right-menu .item .text h3{
        font-size: 4.4vw;
    }
    .karavanKirala .content .right-menu .item .text p{
        font-size: 3vw;
        width: 75% !important;
    }
    .karavanKirala .content .right-menu .item{
        margin: 25px !important;
    }
    .karavanKirala .content .right-menu button{
        margin: 0 !important;
        font-size: 3.5vw;
        padding: 5px 30px;
    }
}
.giveRent{
    position: relative;
    width: 100%;
    height: 720px;
}
.giveRent img#imgg{
    position: absolute;
    width: 100%;
    top: -100px;
    z-index: 0;
}

.giveRent .content{
    width: 100%;
    display: flex;
    justify-content: space-around;
    position: absolute;
    padding: 60px 8%;
}
.giveRent .left-menu h1:nth-child(1){
    color: #2689B2;
}
.giveRent .left-menu h1:nth-child(2){
    color: #196CA5;
}
.giveRent .left-menu h1{
    font-size: 3vw;
    line-height: 120.5%;
}
.giveRent .left-menu .item{
    margin-top: 35px;
}
.giveRent .right-menu{
    display: grid;
    place-items: center;
}
.giveRent .right-menu img{
    width: 80%;
    height: auto;
}
.giveRent .item{
    display: flex;
    align-items: center;
}
.giveRent span#circle{
    border: 1px solid #196da554;
    color: #196CA5;
    width: 10px;
    height: 10px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    border-radius: 100%;    
    margin: 10px;
}
.giveRent .item{
    display: flex;
    align-items: center;
}
.giveRent .text h3{
    font-weight: 500;
    color: #196CA5;
}
.giveRent .text p{
    font-size: 1vw;
    color: #3D434C;
}

@media only screen and (max-width: 1000px) {
    .giveRent{
         height: 1000px;
    }
    .giveRent .right-menu img{
    width: 100%;
    height: auto;
}
   .giveRent .content h1{
    font-size: 7vw;
    width: 100% !important;
    text-align: start;
   }
   .giveRent .left-menu{
      margin-top: 35px;
   }
   .giveRent .content{
    flex-direction: column-reverse !important;
   
   }
   .giveRent .content .left-menu .text p{
    font-size: 3vw;
   }
   .giveRent .content .buttons button{
    font-size: 4vw;
   }
   .giveRent .content .buttons{
    margin-bottom: 35px;
   }
}
.blog{
    text-align: center;
}
.blog h1:nth-child(1){
    color: #2689B2;
}
.blog h1:nth-child(2){
    color: #196CA5;
}
.blog h1{
    font-size: 50px;
    line-height: 120.5%;
}
.blog-cards{
    width: 100%;
    margin-top: 35px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}


.mob-blog-sugg{
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    
}
.blog_link{
    margin: 15px !important;
}
@media only screen and (max-width: 1000px) {
    .mob-blog-sugg{
        flex-wrap: wrap;
        padding: 0px !important;
    }
    .blog_link{
        margin: 15px 0 !important;
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .blog_link p{
        max-width: 250px;
    }
} 



.blog_link{
    width: 370px;
    height: 330px;
    background: #FFFFFF;
    box-shadow: 0px 4px 28px -1px rgba(0, 0, 0, 0.17);
    border-radius: 18px;
    margin: 0 30px;
}
.blog_link img{
    border-radius: 15px;
    height: 230px;
    width: 370px !important;
    object-fit: cover;
}
.blog_link p{
    color: #000;
    margin: 15px;
    text-align: center !important;
}
.mob-blog-sugg{
    display: flex;
    justify-content: center;
    padding: 50px 150px;
    margin-bottom: 40px;
}
button.blog-all-btn{
    width: 250px;
    border: 2px solid #BFD4E5;
    border-radius: 30px;
    text-align: center;
    color: #196CA5;
    background-color: #FFF;
    margin-bottom: 150px;
    padding: 10px 20px;
    font-size: 1.2rem;
    font-weight: 600;
}
.map h1:nth-child(1){
    color: #2689B2;
}
.map h1:nth-child(2){
    color: #196CA5;
}
.map h1{
    font-size: 50px;
    line-height: 120.5%;
}
.map img{
    margin-top: 60px;
    width: 75%;
    height: auto;
}
.map{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 760px;
    margin-bottom: 60px;
    padding-top: 70px;
}
@media only screen and (max-width: 1000px) {
    .map{
        padding: 0;
        justify-content: flex-start;
        height: 350px;
    }
    .map h1{
        font-size: 40px;
        line-height: 120.5%;
    }
    .map img{
        width: 90%;
    }
} 
.banner{
    background: #0EA3CA;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 300px;
}

.banner .part ul li{
    color: #FFF;
}
@media only screen and (max-width: 1000px) {
    .banner .part ul li{
        font-size: 3vw;
    }
}
.contact-us-header{
    background-image:url(/static/media/bg.568e64b0.png);
    background-repeat:no-repeat;
    background-size:contain;
    background-position:center;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    text-align: center;
    padding: 0px 10%;
}
.contact-us-header .title{
    margin-top: 12vh;
    margin-bottom: 7vh;
    -webkit-animation: fade-down 0.7s;
            animation: fade-down 0.7s;
}
.contact-us-header .title h1{
    font-size: 3vw;
    font-weight: 600;
}
.contact-us-header .title p{
    margin-top: 15px;
    width: 300px;
    color: #848484;
}
.contact-section{
    margin-bottom: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: center;
}
.contact-section .flex-1{
    width: 50%;
    margin-right: 35px;
    height: 45vh;
}
.contact-section .flex-1 form{
    display: flex;
    flex-direction: column;
}

.contact-section form input, form textarea{
    max-width: 100% !important;
    cursor: pointer;
    margin-bottom: 15px;
    border-radius: 30px;
    width: 100%;
    padding: 15px;
}
form textarea{
    height: 200px;
    resize: none;
}
.contact-section input[type=text], textarea{
    background-color: #F9F9F9;
    border: 1px #EFEFEF solid;
}
.contact-section input[type=submit]{
    color: #FFF;
    background: linear-gradient(94.76deg, #117AA7 9.47%, #0DA2C9 73.37%);
}
@media only screen and (max-width: 1000px) {
    .contact-section{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        height: 100vh;
        
    }
    .contact-us-header{
        padding: 0;
    }
    .banner{
        flex-direction: column;
    }
    .banner .flex-1 .part ul{
        padding: 0 25px;
    }
    .banner{
        margin-top: 15px;
        padding: 25px 0;
    }
    .banner img{
        width: 50%;
    }
    .banner .flex-2 img.logo{
        margin-left: 25%;
    }
    .contact-us-header .title h1{
        font-size: 7vw;
    }
    .contact-section .flex-1{
        align-items: center;
        width: 100%;
        justify-content: center;
        display: flex;
        flex-direction: column;
        margin: 0;
    }
    .contact-section .flex-1 form{
        width: 100%;
    }
    .contact-section .flex-1 form input{
        width: 100%;
    }
}


#google-map{
    width: 600px;
    height: 400px;
}

@media only screen and (max-width: 1000px) {
    #google-map{
        width: 300px;
        height: 500px;
    }
}
/* NAVBAR */
.navbar-white{
    background-color: #FFF;
    display: flex;
    align-items: center !important;
    justify-content: space-between;
    box-shadow: 0px 4px 23px rgba(0, 0, 0, 0.1);
    padding: 20px 8%;
}

.left-menu-white{
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.left-menu-white ul{
    display: flex;
}

.left-menu-white ul li{
    font-size: 0.9vw;
    cursor: pointer;
    margin-left: 25px;
    list-style-type: none;
    font-weight: 500;
}
.after-logged, .before-logged{
    display: flex;
}

.hamburger-menu{
    display: none;
}
@media only screen and (max-width: 1000px) {
    .left-menu-white ul{
        display: none;
    }
    .right-menu-white .after-logged,.right-menu-white .before-logged{
        display: none;
    }
    .hamburger-menu{
        display: block;
    }
    .navbar-white{
        flex-direction: row-reverse;
    }
    .navbar-white img.logoCaravinn{
        left: 32%;
        top: 15px;
        width: 40%;
        position: absolute;
    }
}
.header-content{
    width: 100%; 
    display: flex;
    justify-content: space-around;
    padding: 10% 15%;
    position: relative;
    margin-bottom: 70px;
}
img.headerbg{
    width: 100%;
    position: absolute;
}
.header-left h1{
    width: 40%;
    font-size: 3.5vw;
    -webkit-animation: fade-right 0.7s;
            animation: fade-right 0.7s;
}
.header-right{
    width: 32%;
    display: flex;
    flex-direction: column;
    -webkit-animation: fade-left 0.7s;
            animation: fade-left 0.7s;
}
.header-right span{
    margin: 10px 0;
}
.header-right span:nth-child(2n-1){
    font-size: 1.1vw;
    font-weight: 300;
}
.header-right span:nth-child(2n){
    font-size: 1.4vw;
    font-weight: 600;
}

  
@media only screen and (max-width: 1000px) {
    .header-content{
        padding-top: 50px !important;
        padding: 0;
        align-items: center;
        margin-bottom: 10px;
    }
    .header-content .header-left h1{
        font-size: 5vw;
    }
    .header-right span:nth-child(2n-1){
        font-size: 2vw;
    }
    .header-right span:nth-child(2n){
        font-size: 3vw;
    }
    .header-right span{
        margin: 5;
    }
    img.headerbg{
        display: none;
    }
}

img.information-section-bg{
    position: absolute;
    width: 100%;
    z-index: 0;
}
.information-section .flex{
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
    padding: 9% 15%;
    
    color: #FFF;
}
.information-section .flex .flex-1{
    width: 60%;
}
.information-section .flex .flex-1 h1{
    font-size: 3vw;
    font-weight: 700;
    margin-bottom: 15px;
    width: 100%;
}
.information-section .flex-1 .item{
    display: flex;
    align-items: center;
    margin: 15px;
    margin-left: 0;
}
.information-section .flex-1 .item span#circle{
    border: 1px solid #FFF;
    width: 10px;
    height: 10px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    border-radius: 100%;    
    margin: 10px;
    margin-left: 0;
}
.information-section .text h3{
    font-weight: 500;
}
.information-section .text p{
    margin-top: 8px;
    font-size: .7vw;
}

.information-section .ed{
    margin-top: 5px;
    margin-right: 0;
}

img.information-hand-img{
    width: 100%;
}

  
@media only screen and (max-width: 1000px) {
    .information-section .flex{
        flex-direction: column-reverse;
        padding-top: 20px !important; 
        padding: 0;
        height: 100%;
        background-color: #0B91C5;
        align-items: center;
        justify-content: center;
    }
    .information-section .flex .flex-2{
        text-align: center;
    }
    .information-section .flex .flex-2 img{
        width: 50%;
    }
    .information-section .flex .flex-1 h1{
        font-size: 4vw;
        text-align: center;
        margin-top: 15px;
    }
    .information-section .flex .flex-1 .item .text p{
        font-size: 3vw;
    }
    .information-section .flex .flex-1{
        justify-content: center !important;
        align-items: center !important;
        width: 100%;
    }
    .information-section .flex .flex-1 .item{
        margin: 0;
        margin-bottom: 10px;
        width: 100%;
        justify-content: space-around;
        padding: 0 30px;
    }
    button.ed{
        font-size: 4vw;
        margin-left: 30%;
        margin-bottom: 5%;
    }
}
.advantages{
    height: 1000px;
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 9%;
    
}
.advantages-text{
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.advantages-img-header{
    margin-bottom: 150px;
    width: 75%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.advantages-img-header h1{
    max-width: 700px;
    font-style: normal;
    font-weight: 600;
    font-size: 3vw;
    line-height: 5vw;
    margin-left: 35px;
}

.advantages .flex{
    width: 40%;
    display: flex;
}

    





@media only screen and (max-width: 1000px) {
    .advantages{
        max-width: 100%;
        padding: 0;
        height: 1400px;
        flex-direction: column;
        margin: 0;
    }
    .advantages-img-header{
        flex-direction: column;
        margin: 0;
    }
    .advantages-img-header img{
        width: 50%;
        margin-bottom: 25px;
    }
    .advantages-img-header h1{
        font-size: 6vw;
        line-height: 35px;
    }
    .advantages-text .flex .flex-2, .advantages-text{
        flex-direction: column;
    }
    .advantages-text .flex .flex-2 p{
        text-align: center;
        min-width: 100%;
    }
    .advantages-text{
        max-width: 100% !important;
        justify-content: center;
        align-items: center;
    }
    .mb{
        margin-top: 35px;
    }
    

    .mb{
        display: none;
    }
}
.blue-section{
    background-color: #21A7DB;
    width: 100%;
    padding: 30px 7%;
    padding-left: 12%;
}

img.blue-section-img{
    width: 80%;
}

.blue-section .flex{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    color: #FFF;
}

@media only screen and (max-width: 1000px) {
    .blue-section{
        padding: 50px;
    }
    .blue-section .flex{
        align-items: center;
        justify-content: center;
        flex-direction: column-reverse;
    }
    .blue-section .flex .flex-1, .blue-section .flex .flex-2{
        text-align: center;
    }
}

.features-cards{
    padding: 140px 200px;
}

.horz-cards, .vert-cards{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 55px;
}

/* HORIZONTAL CARDS */
.card-1{background-color: #FCFCFC;}
.card-2{background-color: #E2F0FF;}
.card-1, .card-2{
    width: 45%;
    height: 280px;
    padding-left: 35px;
    padding-right: 15px;
}

.fea-card h3{
    font-weight: 500;
    font-size: 32px;
}
.card-1 p, .card-2 p{
    color: #7E7E7E;
}



/* SECOND CARDS SECTION */
.card-3{
    width: 72%;
    padding: 25px 45px;
    color: #FFF;
    background: 
    linear-gradient(139.36deg, #117AA7 27.52%, #BCEBFF 85.09%);
}
.card-3 p{
    width: 350px;
    margin-top: 15px;
    font-size: 20px;
}
.card-4{
    padding: 24px;
    width: 25%;
    background-color: #F5F5F5;
    flex-direction: column;
    text-align: center;
}
.card-4 p{
    width: 210px;
}
.fea-card{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 30px;
}

.fea-card img{
    width: 20%;
}
.card-4 img{
    width: 30%;
    margin: 30px 0;
}
.card-4{
    padding: 4%;
}
.card-3{
    padding: 4% 6%;
}
.card-3 img{
    margin: 50px 0;
}
.card-2 img, .card-1 img{
    margin: 50px;
}


@media only screen and (max-width: 1000px) {
    .features-cards{
        display: none;
    }
}

.give-rent-header{
    height: 870px;
    width: 100%;
    background: no-repeat url(/static/media/header-bg.e7103dbd.svg);
    background-size:contain;
    background-position:center;
    display: flex;
    justify-content: space-around;  
    align-items: center;
    padding: 3% 14%;
}

.give-rent-header h1{
    font-size: 3vw;
    margin-bottom: 10px;
    font-weight: 600;
}
.give-rent-header h2{
    line-height: 150%;
    font-weight: 500;
    font-size: 1.5vw;
    margin-bottom: 10px;
}

.give-rent-header .text-1 h3{
    font-weight: 400;
}

.give-rent-header .flex-1{
    height: 100%;
    padding-top: 80px;
    padding-bottom: 80px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    -webkit-animation: fade-right 0.7s;
            animation: fade-right 0.7s;
}


.flex-2 img.cycle-img{
    margin-top: 50px;
    width: 100%;
    -webkit-animation: fade-left 0.7s;
            animation: fade-left 0.7s;
}

.text-2 p{
    max-width: 320px;
    line-height: 171.5%;
    color: #666666;
} 

@media only screen and (max-width: 1000px) {
    .give-rent-header{
        flex-direction: column-reverse;
    }
}
.caravans-header{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding-bottom: 85px;
    height: 50vh;
    background: no-repeat linear-gradient(180.3deg, #006E9D 0.26%, #BBEBFF 79%);
}
.caravans-header img{
    position: absolute;
    width: 73%;
}
.caravans-header h1{
    font-size: 3vw;
    color: #FFF;
}

@media only screen and (max-width: 1000px) {
    .caravans-header h1{
        font-size: 7vw;
        color: #FFF;
    }
    .caravans-header{
        height: 50vh;
        padding-bottom: 0;
    }
    
}


@media only screen and (max-width: 1000px) {
    .test-2341 .wrapper-white{
        padding-left: 5px !important;
        padding-right: 5px !important;
    }

    .test-2341 .caravans-buttons{
        display: flex;
        flex-direction: column !important;
        align-items: center;
    }
    .test-2341 .caravans-buttons button{
        font-size: 3.6vw;
        margin: 0;
        margin-bottom: 10px;
        width: 50%;
    
    }
    .caravans-header{
        height: 40vh;
    }
    .caravans-header h1{
        margin-bottom: 80px !important;
        font-size: 7.2vw;
    }
}
.signup-main{
    background: no-repeat url(/static/media/bg.b248912b.svg);
    height: 100%;
    padding-bottom:150px;
}
.signup-component{
    margin-top: 30px;
    display: grid;
    place-items: center;
}
.signup-component .signup-title h1{
    font-size: 2.6vw;
}
.signup-component .signup-title p{
    font-size: 1.1vw;
}
.signup-component .signup-title{
    text-align: center;
    color: #FFF;
    margin-bottom: 55px;
}

@media only screen and (max-width: 1000px) {
    .signup-component .signup-title h1{
        font-size: 5vw
    }
    .signup-component .signup-title p{
        font-size: 3vw;
    }
    .login-card{
        width: 80%;
        
    }
}
         
.login-main{
    background: no-repeat url(/static/media/bg.b248912b.svg);
    height: 100vh;
}
.login-component{
    margin-top: 80px;
    display: grid;
    place-items: center;
}
.login-component .login-title h1{
    font-size: 2.6vw;
}
.login-component .login-title p{
    font-size: 1.1vw;
}
.login-component .login-title{
    text-align: center;
    color: #FFF;
    margin-bottom: 55px;
}

.login-card{
    width: 420px;
    height: 100%;
    padding: 45px 25px;
    background-color: #FFF;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.login-card input{
    padding: 15px;
    border: 1px solid #EFEFEF;
    border-radius: 60px;
    margin-bottom: 20px;
}

.login-card button{
    background: linear-gradient(94.76deg, #117AA7 9.47%, #0DA2C9 73.37%);
    padding: 15px;
    border-radius: 60px;
    color: #FFF;
}
.login-card h4{
    text-align: center;
    margin-top: 20px;
    color: #000;
    font-weight: 400;
}
.login-card h4 a{
    margin-left: 5px;    
    color: #0B91C5;
    font-weight: 600;                   
}

@media only screen and (max-width: 1000px) {
    .login-component .login-title h1{
        font-size: 5vw
    }
    .login-component .login-title p{
        font-size: 3vw;
    }
    .login-card{
        width: 80%;
        
    }
}
.footer{
    height: 600px;
    width: 100%;
    position: relative;
    background: url(/static/media/bg.30007e8d.svg) no-repeat;
    background-size: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 30px 10%;
    padding-top: 230px;
}

.footer img#icon{
    cursor: pointer;
    width: 75px;
    right: 45px;
    top: 45px;
    position: absolute;
}
.social-media span{
    cursor: pointer;
    margin-right: 10px;
    margin-top: 20px;
    height: 32px;
    width: 32px;
    text-align: center;
    padding: 4px;
    color: #0B91C5;
    border:1px solid #E9E9E9;
    border-radius: 50%;
    display: inline-block;
}
hr{
    height: 2px;
    background-color: #000;
}
.parts{

    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.part img#logo{
    cursor: pointer;
}
.part h4{
    color: #196CA5;
    margin-bottom: 15px;
}
.part ul li{
    cursor: pointer;
    margin-bottom: 18px;
    
    color: #196da594;
    font-weight: 500;
    display: flex;
    align-items: center;
    font-size: .9vw;
}
.copyright-text{
    color: #196CA5;
    font-weight: 500;
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.copyright-text p{
    font-size: .9vw;
}
.terms-conditions ul{
    width: 100%;
    display: flex;
    justify-content: space-around;
}
.terms-conditions ul li{
    margin: 0 10px;
    cursor: pointer;
    font-size: .8vw;
}
.plus-icon-footer, ul#mob-version{display: none;}
@media only screen and (max-width: 1000px) {
    .footer{
        height: 700px;
      
    }

    .parts{
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
    }
    .part h4{
        font-size: 7vw;
        margin-top: 25px;
    }
    .part ul li{
        font-size: 4vw;
    }
    .copyright-text{
        flex-direction: column;
        text-align: center;
    }
    .copyright-text p{
        margin-bottom: 25px;
        text-align: center;
    }
    .copyright-text p, .copyright-text .terms-conditions ul li{
        font-size: 2.5vw;
    }
    .part{
        width: 100% !important;
    }
    .f-mb-footer{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .closed-mb ul{
        display: none;
    }
    .plus-icon-footer{
        font-size: 1.5rem;
        margin-top: 10px;
        color: #0B91C5;
        font-weight: 800;
        display: block;
    }
    ul#mob-version{
        display: block;
    }
} 
.profile-header{
    background-color: #1E98C7;
    display: flex;
    align-items: center;
    padding-left: 10%;
    color: #F5f5f5;
    height: 90px;
}
.profile-header h1{
    font-weight: 600;
}
.profile-panel{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 20%;
}

.menu{
    padding-left: 150px;
    padding-right: 100px;
    padding-top: 60px;
    background-color: #FFF;
    height: 100%;
    border-radius: 0 0 15px 0;
    padding-bottom: 100%;
}

.profile-info{
    position: relative;
    margin-top: 60px;
    background-color: #FFF;
    border-radius: 30px;
    height: 50vh;
    width: 100%;
    margin-left: 300px;
}
.grey-div{
    position: absolute; 
    background-color: #F9F9F9;
    border-radius:30px 30px 0 0;
    width: 100%;
    height: 150px;
}
.profile-info img{
    position: relative;
    margin-left: 45px;
    margin-top: 55px;
    height: 150px;
}
button.edit{
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 20px;
    margin-right: 25px;
    background-color: transparent;
    color: #0B91C5;
    font-weight: 600;
    font-size: 16px;
}

.user-information{
    position: absolute;
    bottom: 28%;
    left: 240px;
    display: flex;
    flex-direction: column;
}

.user-information h1{
    margin-bottom: 30px;
}
.user-information p{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    color: #000;
}
.user-icon{
    margin-right: 10px;
    color: #1E98C7;
}
button.change-password{
    position: absolute;
    bottom: 0;
    right: 0;
    margin-bottom: 20px;
    margin-right: 20px;
    padding: 10px 15px;
    border-radius: 30px;
    background-color: transparent;
    border: 2px solid #1E98C7;
    color: #0B91C5;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;
}


/* LEFT MENU */

.menu ul{
    display: flex;
    flex-direction: column;
}
.menu ul li{
    padding: 10px;
    font-size: 1.2rem;
}
.menu ul li span{
    display: flex;
    align-items: center;
}
.panel-icons{
    margin-right: 10px;
}



/* MODAL */
.title{
    font-weight: 600;
}
.change-password .error{
    width: 100%;
    text-align: center;
    margin-top: 10px;
}
.change-password{
    display: flex;
    flex-direction: column;
}
.change-password input{
    padding: 15px;
    border: 1px solid #EFEFEF;
    border-radius: 30px;
    color: #B5B5B5;
    margin: 10px 0;
}
.change-password input[type=button]{
    background: linear-gradient(94.76deg, #117AA7 9.47%, #0DA2C9 73.37%);
    border-radius: 60px;
    color: #FFF;
    cursor: pointer;
    margin-bottom: 0;
}
.exit span{
    position: absolute;
    right: 0;
    top: 0;
    margin-right: 20px;
    margin-top: 15px;
    cursor: pointer;
}

@media only screen and (max-width: 1000px){
    .profile-panel{
        flex-direction: column;
        padding: 0;
       
    }
    .profile-panel .menu{
        padding: 0;
    }
    .profile-info{
        border-radius: 0;
        height: 700px;
        margin: 0;
        flex-direction: column;
        align-items: center;
       padding: 0px 25px;
        justify-content: center;
    }
    .user-information, button.change-password, button.edit{
        position:static;
    }
    .grey-div{
        display: none;
    }
    .profile-info img{
        margin: 0;
        margin: 50px 0;
    }
}
.faqs {
	width: 100%;
	max-width: 768px;
	margin: 0 auto;
	padding: 15px;
	transition: all 0.4s ease;
}

.faqs .faq {
	margin: 15px;
	padding: 30px 20px;
	background-color: #FFF;
	border-radius: 18px;
	transition: all 0.4s ease;
}

.faqs .faq .faq-question {
	position: relative;
	font-size: 18px;
	padding-right: 80px;
    line-height: 30px;
	transition: all 0.4s ease;
    color: #170F49;
}

.faqs .faq .faq-question::after {
	content: '';
	position: absolute;
	top: 50%;
	right: 0px;
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%);
	width: 30px;
	height: 30px;
    
	background-image: url(/static/media/arrow-down-mint.04261271.svg);
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;

	transition: all 0.4s ease-out;
}

.faqs .faq .faq-answer {
    color: #6F6C90;
	opacity: 0;
	max-height: 0;
	overflow-y: hidden;
	transition: all 0.4s ease-out;
}

.faqs .faq.open{
    border: 2px solid #0B91C5;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}
.faqs .faq.open .faq-question {
	margin-bottom: 15px;
}

.faqs .faq.open .faq-question::after {
	-webkit-transform: translateY(-50%) rotate(-90deg);
	        transform: translateY(-50%) rotate(-90deg);
}

.faqs .faq.open .faq-answer {
    max-width: 90%;
	max-height: 1000px;
	opacity: 1;
}

.faq-page{
    background: no-repeat url(/static/media/bg1.b248912b.svg);
	height: 100%;
}
.bg-blue{
	height: 100%;	
	background: #006E9D;
	width: 100%;
}
.faq-page-content{
    padding: 100px;
    display: grid;
    place-items: center;
}
.faq-page-content h1{
    margin-bottom: 40px;
    font-size: 3vw;
}

@media only screen and (max-width: 1000px) {
	.faq-page-content{
		padding: 0;
	}
	.faq-page-content h1{
		font-size: 6vw;
		margin-bottom: 10px;
		margin-top: 10px;
	}
}
.blog-main-page-card-header{
    padding: 0 13%;
}
.blog-card-header{
    width: 100%;
    margin-top: 50px;
    position: relative;
    background-repeat:no-repeat;
    background-size:contain;
    background-position:center;
}

.blog-card-header h3{
    font-size: 1.3vw;
    max-width: 450px;
    font-weight: 500;
    color: #FFF;
    bottom: 40px;
    left: 40px;
    position: absolute;
}
.circles{
    display: flex;
}
.blog-card-header .circles{
    position: absolute;
    bottom: 40px;
    right: 40px;
}
.circles img{
    margin: 0 5px;
    cursor: pointer;
    border-radius: 30px;
}
.circles img.active{
    cursor:auto;
    background-color: #FFF;
}
@media only screen and (max-width: 1000px) {
    .blog-main-page-card-header{
        padding: 0 5%;
    }
    .blog-card-header h3{
        bottom: 15px;
        left: 5px;
        font-size: 2vw;
    }
    .circles{
        display: none;
    }
}
.blog-main-page{
    background: #FFF;
    height: 100%;
}

.blog-main-page-content{
    width: 100%;
    height: 100%;
    background-color: #FFF;
    margin-top: 75px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.blog-main-page-content h1{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 2.3vw;
    font-weight: 600;
}
.blog-main-page-content img.blog-main-bg{
    position: absolute;
    width: 100%;
}
@media only screen and (max-width: 1000px) {
    .blog-main-page-content h1 span{
        font-size: 12vw;
    }
    .blog-main-page-content h1{
        margin-bottom: 50px;
    }
    .blog-main-page-card-header{
        display: none;
    }
    .btn.btn-blue.adsss{
        font-size: 4vw;
        padding: 10px;
    }

}
.btn{
    cursor: pointer !important;
    width: auto;
    padding: 10px 20px;
    border-radius: 30px;
    font-weight: 500; 
    margin-right: 15px;
    font-size: 18px;
}
@media only screen and (max-width: 1550px) {
.btn{
    font-size: .99vw;
    padding: 5px 10px;
    margin-right: 5px;
}

} 
.btn-white{
    background-color: #FFFF;
    color: #2094C6;
}
.btn-transparent{
    background-color: transparent;
    color: #FFFF;
    border: 2px solid #FFFF;
}
.btn-transparent-blue{
    background-color: transparent;
    color: #196CA5;
    border: 1px solid #EEEEEE;
    margin-left: 10px;
}
.btn-blue{
    background: linear-gradient(94.76deg, #117AA7 9.47%, #0DA2C9 73.37%);
    color: #FFFF;
}
.btn-blue-big{
    background: linear-gradient(94.76deg, #117AA7 9.47%, #0DA2C9 73.37%);
    border-radius: 110px !important;
    color: #FFFFFF;
    font-size: 1.2vw;
    text-align: center; 
}
.btn-circle{
    width: 50px;
    height: 50px;
    border-radius: 100%;
    color: #0DA0C9;
    margin-right: 10px;
    padding: 0;
}

.btn-blue-small{
    background: linear-gradient(101.45deg, #127BA8 3.27%, #BDECFF 108.56%);
    border-radius: 60px;
    color: #FFF;
}

.active-button{
    background: linear-gradient(94.76deg, #117AA7 9.47%, #0DA2C9 73.37%);
    color: #FFFF;
}

.navbar-white-btn-blue{
    background-color: transparent;
    color: #1E98C7;
    border: 2px solid #1E98C7 !important;
    font-weight: 600;
}


.bl-bg{
    background: no-repeat linear-gradient(180.3deg, #006E9D 0.26%, #BBEBFF 79%);
}
.blog-details-header-main{
    
    background: url(/static/media/bg.cddd4f9b.svg) no-repeat;
    color: #FFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}   

.blog-details-header-main h3{
    margin: 50px 0;
    display: flex;
    align-items: center;
    font-weight: 600;
}

.blog-details-header-main span{
    margin-bottom: 30px;
}

img.blog-details-header-img{
    z-index: 8;
    width: auto;
    border-radius: 35px;
    max-height: 60vh;
}
.blog-details-header-div{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 75%;
    height: 600px;
    background-color: transparent;
    z-index: 10;
}


@media only screen and (max-width: 1000px) {
    .blog-details-header-main h1{
        text-align: center;
        font-size: 7vw;
        max-width: 79vw !important;
    }
    .blog-details-header-main{
        background-image: none  ;
    }
    .blog-details-wrapper h1{
        max-width: 350px !important;
        font-size: 7vw;
    }
    .login-signup-comp{
        flex-direction: column;
    }
     .blog-details-header-div{
        height: 450px;
    }
    img.blog-details-header-img{
        width: 80vh;
        position: relative;
        z-index: 1 !important;
    }
}

.wrapper-white{
    background-color: #FFF;
    position: relative;
    bottom: 300px;
    z-index: 2;
    border-radius: 36px 36px 0 0;
    padding-top: 350px;
}

.blog-details-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
}

.blog-details-wrapper .logo-caravinn .logo-title p{
    width: 850px;
    margin-bottom: 40px;
}
.blog-details-wrapper h1{
    margin-bottom: 30px;
    max-width: 620px;
}

.blog-details-wrapper .logo-title h3{
    font-weight: 500;
    font-size: 24;
}
.blog-details-wrapper .logo-title p{
    margin-bottom: 0;
    font-size: 600;
    color: #9897A3;
    font-size: 16;
}




.login-signup-comp{
    font-weight: 400;
    color: #67727E;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 70px 0;
    width: 100%;
    background-color:#FAFAFA;
    padding: 50px;
}
.login-signup-comp #link{
    color: #0B91C5;
    font-weight: 600;
    margin: 0 5px;
}

.comment-comp{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin: 70px 0;
    width: 100%;
    background-color:#FAFAFA;
    padding: 50px;
}

.comment-comp input[type=text]{
    margin: 0 20px;
    border: 1px solid #E9EBF0;
    padding: 10px 20px;
    border-radius: 14px;
    color: #67727E;
    width: 30%;
}
.comment-comp input[type=submit]{
    padding: 10px;
    border-radius: 60px;
    color: #FFF;
    background: linear-gradient(94.76deg, #117AA7 9.47%, #0DA2C9 73.37%);
}
.comments-comp{
    display: flex;
    flex-direction: column;
    margin: 70px 0;
    width: 100%;
    background-color:#FAFAFA;
    padding:50px 24%;
}
.comments-comp h2{
    width: 100%;
    text-align: left;
}

.comment-card{
    display: flex;
    background-color: #FFF;
    border-radius: 15px;
    padding: 20px;
}
.comment-card .quantity-card{
    background-color: #F8F8F8;
    display: flex;
    flex-direction: column;
    color: #0B91C5;
    height: 100%;
    padding: 15px;
    border-radius: 15px;
    margin: 15px;
    margin-right: 35px;
}
.quantity-card span{
    font-weight: 600;
    margin: 15px 0;
}
.comment-card-content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.prof{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-top: 10px;
}
.reply-btn{
    display: flex;
    align-items: center;
    color: #0B91C5;
    font-weight: 600;
}
.reply-btn span{
    margin-left: 10px;
}
.prof h3{
    font-weight: 500;
    margin-left: 30px;
    margin-right: 40px;
}
.comment-card-flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-right: 10px;
}

.comment-content p{
    text-align: left;

}





.admin-left-menu{
    background-color: #FFF;
    color: #FFF;
    height: 150vh;
    padding: 2% 0;
    min-width: 15%;
}
.admin-page-content{
    display: flex;
    justify-content: space-between;
    padding: 20px;
  
}
.admin-left-menu ul li{
    padding: 20px 30px;  
    transition: 0.8s;
}
.admin-left-menu ul li:hover{
    background-color: #c5dce5; 
    transition: 0.8s;
}
.admin-left-menu ul .link{
    color: #0B91C5;
    font-weight: 600;
}
.input-data textarea{
    width: 100%;
    border-radius: 0;
}
.input-data{
    margin: 15px;
    display: flex;
    align-items: center;
}
select#select-filter{
    width: 25%;
    margin-left: 30px;
    height: 50px;
    text-align: center;
}
.input-data input, select{
    height: 50px;
    width: 100%;
    padding: 15px;
}
.input-data label{
    width: 150px;
    margin-right: 15px;
}
.admin-page-content button#insert{
    width: 350px;
    padding: 15px;
    margin-top: 10px;
    background-color: #FFF;
    color: #000;
    font-weight: 600;
}
.insert{
    width: 50%;
    display: flex;
    flex-direction: column;
}
.table{
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    width: 80%;
    padding-right: 15px;
}
.table input{
    width: 100%;
    padding: 10px;
    font-size: 1.4rem;
    margin-bottom: 20px;
}
table {
    background-color: #FFF;
    padding: 35px;
    border-radius: 15px;
} 
.is tr th{
    padding: 15;
}
th{
    border-bottom: 2px solid #E6F1F6;
    padding: 20px;
}
td {
    padding: 24px;
    text-align: center;
}
td#blog_body{
    max-width: 400px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.admin-panel{
    display: flex;
    height: 100%;
    background-color: #E6F1F6;
}
.admin-stats-cards{
    
    display: flex;
    justify-content: space-between;
    padding: 0% 10%;
}
.admin-stats-cards .stats-card{
    background-color: #FFF;
    border-radius: 15px;
    padding: 25px;
    width: 95%;
    margin: 15px;
}
.about-header h1{
    font-size: 2.5vw;
    color: #FFF;
}
.about-header{
    position: relative;
    height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
@media only screen and (max-width: 1000px) {
    .about-header h1{
        font-size: 20px;
    }
    .about-header img{
        width: 75%;
    }
    
}


.about-wrapper-main{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.circle-cards{
    display: flex;
    position: absolute;
    bottom: 230px;
    flex-wrap: wrap;
}
.circle-card{
    box-shadow: 0px 4px 36px 3px rgba(155, 208, 255, 0.3);
    border-radius: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 25px;
    width: 265px;
    height: 265px;
    background-color: #FFF;
}
.circle-card img{
    margin: 15px;
}
.circle-card h4{
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    color: #000000;
}
.circle-card span{
    color: #1E98C7;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;

}
.about-wrapper-content{
    margin-top: 100px;
    padding: 2% 0;
    text-align: center;
}
.about-wrapper-content h1{
    margin-bottom: 30px;
}
.about-wrapper-content p{
    max-width: 800px;
    color: #848484;
}

@media only screen and (max-width: 1000px) {
    .circle-cards{
        display: none;
    }
    .about-wrapper-content{
        padding: 0 20px;
    }
    .about-wrapper-main{
        height: 500px;
    }
}
.info-section-text h2{
    max-width: 600px;
    font-size: 1.6vw;
    margin-bottom: 20px;
}

.info-section-text p{
    max-width: 580px;
    color: #848484;
}
.info-section-main{
    background-color: #FFF;
    height: 700px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 10%;
}
.info-section-2-main{
    margin-top: 120px;
    background-color: #F7F9FB;
    height: 700px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 10%;
}

@media only screen and (max-width: 1000px) {
    .info-section-2-main, .info-section-main{
        flex-direction: column;
        height: 500px;
        margin-top: 200px;
    }
    .info-section-text h2{
        font-size: 5vw;
    }
}
.our-team{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 80px 0;
}
.our-team h1{
    margin-bottom: 35px;
}
.our-team-cards{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 80vw;
    justify-content: center;
}
.our-team-card{
    margin: 20px;
    padding: 30px;
    width: 300px;
    border-radius: 30px;
    border: 2px solid #EEF1F3; 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.our-team-card span{
    text-align: center;
}
.our-team-card img{
    margin-bottom: 20px;
}
@media only screen and (max-width: 1000px) {
    .our-team-cards{
        flex-direction: column;
        align-items: center;
        width: 100vw;
        justify-content: center;
    }
    .our-team-card{
        margin-bottom: 20px;
    }
}

.our-values{
    padding: 120px;
    background: #F7F9FB;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.our-values h1{
    margin-bottom: 40px;
    color: #000;
}
.our-values p{
    margin-bottom: 40px;
    max-width: 900px;
    color: #848484;
}
.value-cards{
    display: flex;
    flex-direction: row;
}
.value-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    border: 2px solid #EEF1F3; 
    padding: 10px;
    margin: 10px 50px;
    width: 300px;
}
.value-card img{
    margin: 30px;
}
.value-card h1{
    margin-bottom: 10px;
}
.value-card p{
    max-width: 200px;
}
@media only screen and (max-width: 1000px) {
    .our-values{
        padding: 20px;
    }
    .value-cards{
        flex-direction: column;
    }
}
.caravan-details-header, .caravan-images, .small-images{
    display: flex;
    align-items: center;
}
.caravan-images{
    width: 50vw;
    justify-content: center;
}
.small-images{
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
    max-height: 600px;
}
.main-image img{
    width: auto;
    height: 650px;
    object-fit: cover;
}
.small-images img{
    margin-bottom: 10px;
    height: 150px;
    object-fit: cover;
}
.slider-class{
        width: 40vw;
        height: 70vh;
        margin: 0 auto;
}
.caravan-details{
    margin-left: 45px;
    margin-top: 45px;
}
.caravan-feauters{
    width: 400px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}
.caravan-feauters span{
    margin: 5px 0;
    display: flex;
    align-items: center;
    color: #1E98C7;
    font-weight: 500;
}
.icon-caravan{
    margin-right: 10px;
    color: #C5C5C5;
}
.caravan-feauters button{
    margin-top: 15px;
    border: 1px solid #1E98C7;
    padding: 15px 10px;
    width: 70%;
    border-radius: 30px;
    font-weight: 600;
    font-size: 1rem;
}
.description hr{
    background-color: #F3F3F3;
    border: 1px;
    margin-top: 20px;
}
.description p{
    margin-top: 15px;
    width: 95%;
    margin-bottom: 50px;
}
.description button{
    margin-top: 15px;
    border: 1px solid #EEEEEE;
    padding: 15px 20px;
    border-radius: 30px;
    font-weight: 600;
    font-size: 1rem;
}
@media only screen and (max-width: 1000px){
    .caravan-details-header{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .caravan-details h1, .caravan-feauters span, .caravan-feauters{
        text-align: center;
        justify-content: center;
    }
    .caravan-feauters button{
        width: 100%;
    }
    .caravan-feauters{
        margin-bottom: 40px;
    }
    .main-image img{
        width: auto;
        height: 250px;
  
    }
    .small-images{
        flex-direction: row;
        width: 100%;
        display: none;
    }
    .small-images img{
        margin-top: 10px;
        border-radius: 0px !important;
        padding: 2px;
        height: 75px;
        
    }
    .caravan-images{
        flex-direction: column;
    }
    .slider-class{
        width: 80vw;
        height: 40vh;
        margin: 0 auto;
}
.caravan-details h1{
    margin-top: 25px;
}
}
.kiraya-verin-comp{
    display: flex;
    padding-left: 200px;
}
.kiraya-verin-left{
    margin-top: 65px;
    margin-right: 120px;
}
.kiraya-verin-left span:first-child{
    color: #1E98C7;
    font-weight: 600;
    font-size: 23px;
}
input[type="date"]{
        border: none;
    box-sizing: border-box;
    outline: 0;
    padding: .75rem;
    position: relative;
    width: 100%;    
}
input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}
.kiraya-verin-left h1{
    font-size: 3rem;
    margin-bottom: 45px;
}
.kiraya-verin-left span#child-2{
    color: #929292;
    font-weight: 500;
    font-size: 14px;
}

.kiraya-verin-radio-btn{
    display: flex;
    justify-content: space-between;
    width: 60%;
    margin: 35px 0;
}


.radio-btn label{
    background-color: #F8F8F8;
    border-radius: 30px;
    padding: 10px;
    color: #929292;
}
.radio-btn{
    display: flex;
    align-items: center;
}
.radio-btn input[type="radio"] {
    margin-right: 10px;
    height: 20px;
    width: 20px;
}
.select-input, input#bedel, .kisisel-bilgi input, textarea{
    background-color: #F8F8F8;
    border-radius: 30px;
    width: 100%;
    padding: 10px;
    color: #929292;
    margin-bottom: 10px;
}
.kisisel-bilgi{
    margin-top: 35px;
    display: flex;
    flex-direction: column;
}
.kisisel-bilgi span{
    margin-bottom: 15px;
}
.name{
    display: flex;
    justify-content: space-between;
}
.name input{
    width: 49%;
}
.kisisel-bilgi #aciklama{
    height: 150px;
    resize: none;
    border-radius: 20px;
}
.kisisel-bilgi input#btn-gonder{
    background-color: #117AA7 !important;
    color: #FFF;
}

@media only screen and (max-width: 1000px){
    .kiraya-verin-comp{
        padding: 0;
        flex-direction: column-reverse;
    }
    .kiraya-verin-left{
        padding: 25px 35px;
        margin: 0;
    }
}
.like-button {
    position: absolute;
    z-index: 2;
    top: 25px;
    right: 29px;
    color: red;
}

.fav-panel{
    display: flex;
    flex-direction: row;
}
.fav-card{
    height: 450px;
    position: relative;
    box-shadow: -5px 5px 19px 5px rgba(0, 0, 0, 0.09);
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    margin: 8px;
}
.fav-card img{
    margin: 7px;
}
.fav-card h3{
    padding: 20px;
}
hr{
    border: 1px solid #EBEBEB ;
    width: 100%;
}
.location{
    margin-left: 10px;
    display: flex;
    align-items: center;
    padding: 15px;
    color: #196CA5;
    font-weight: 500;
}
.location i{
    margin-right: 10px;
    color: #C5C5C5;
}

.icons{
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding: 25px 10px;
}
.icon{
    display: flex;
    align-items: center;
    color: #196CA5;
    font-weight: 500;
}
.icon img{
    margin-right: 15px;
}
.fav-panel-content{
    display: flex;
    padding: 50px;
    height: 75vh;
    flex-wrap: wrap;
}

@media only screen and (max-width: 1000px) {
    .fav-panel, .rented-caravan{
        flex-direction: column;
    }
    .rented-caravan-content{
        height: 100vh;
    }
    .menu{
        padding: 0;
    }
    
}

.edit-panel-content h1{
    margin-top: 70px;
}
.edit-panel-content{
    position: relative;
    padding-left: 25%;
    text-align: center;
    width: 100%;
}
.edit-panel-content button#geri{
    position: absolute;
    left: 0;
    margin: 20px;
    background: linear-gradient(94.76deg, #117AA7 9.47%, #0DA2C9 73.37%);
    border-radius: 60px;
    color: #FFF;
    padding: 10px 25px;
}
.edit-panel{
    display: flex;
    flex-direction: row;
    padding-right: 20%;
}

.edit-card{
    background: #FFFFFF;
    box-shadow: 0px 1px 6px 2px rgba(0, 0, 0, 0.07);
    border-radius: 18px;
    margin-top: 15px;
    display: flex;
    padding: 45px;
    align-items: center;
    flex-direction: column;
}
.edit-card img{
    width: 150px;
    margin-bottom: 20px;
}
.edit-card input{
    width: 100%;
    background: #F8F8F8;
    border-radius: 30px;
    padding: 10px;
    color: #000;
    margin: 10px;
}
.edit-card button{
    margin: 10px;
    background: linear-gradient(94.76deg, #117AA7 9.47%, #0DA2C9 73.37%);
    border-radius: 60px;
    color: #FFF;
    padding: 10px;
    width: 100%;
}
span#icon-flex{
    display: flex;
    width: 100%;
    align-items: center;
}





.edit-btn button{
    position: absolute;
    right: 0;
    margin-right: 15px;
    margin-top: 15px;
    padding: 10px 25px;
    border-radius: 10px;
    color: #1E98C7;
    font-weight: 600;
    background-color: #FFF;
}



/* ADVERT EDIT  */

.advert-edit button#geri{
    position: absolute;
    left: 0;
    margin: 20px;
    background: linear-gradient(94.76deg, #117AA7 9.47%, #0DA2C9 73.37%);
    border-radius: 60px;
    color: #FFF;
    padding: 10px 25px;
}   

.advert-edit{
    display: flex;

}

.advert-edit-content{
    width: 100%;
    padding-left: 20%;
    padding-right: 20%;
    position: relative;
    display: flex;
    
    flex-direction: column;
}
.advert-edit-content h1{
    text-align: center;
    margin-top: 55px;
    margin-bottom: 35px;
}
.advert-edit-card{
    position: relative;
    width: 100%;
    padding: 30px;
    background: #FFFFFF;
    box-shadow: 0px 1px 6px 2px rgba(0, 0, 0, 0.07);
    border-radius: 18px;
}
.advert-edit-card span#card-header{
    color: #929292;
}
.advert-edit-radio-btn{
    display: flex;
    margin: 20px 0;
}
.advert-edit-card .file-images{
    margin: 25px;
}
.advert-edit-content #aciklama{
    resize: none;
    height: 150px;
    border-radius: 20px;
}
.advert-edit-card h3{
    color: #171717;
    font-weight: 500;
    text-align: center;
    margin-top: 10px;
}
.advert-edit-radio-btn .radio-btn:first-child{
    margin-right: 15px;
}
.advert-edit-content button#kaydet{
    margin-top: 10px;
    width: 100%;
    background: linear-gradient(94.76deg, #117AA7 9.47%, #0DA2C9 73.37%);
    border-radius: 60px;
    padding: 10px;
    color: #FFF;
}
.date-calendar{
    background-color: #F8F8F8;
    padding: 15px;
    position: relative;
    margin: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

button#delete-button{
    position: absolute;
    right: 0;
    top: 0;
    color: #FFF;
    background-color: #0DA2C9;
    border-radius: 0 18px 0 18px;
    padding: 15px;
    font-size: 1.5rem;
}

/* PROMOTE BTN */
.promote-btn{
    position: absolute;
}
.promote-btn button{
    background: linear-gradient(94.18deg, #917FFF 12.67%, #63D1FF 76.54%);
    box-shadow: -5px 5px 19px 5px rgba(0, 0, 0, 0.09);
    border-radius: 24px;
    color: #FFF;
    padding: 10px 18px;
    margin-top: 15px;
    margin-left: 15px;
}
.promote-me button{
    background: linear-gradient(94.76deg, #63D0FF 9.47%, #907EFF 73.37%);
    border-radius: 60px;
    color: #FFF;
    width: 90%;
    padding: 15px;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
}
.promote-me-btn button{
    border-radius: 60px;
    color: #FFF;
    width: 90%;
    padding: 15px;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    background: linear-gradient(94.76deg, #117AA7 9.47%, #0DA2C9 73.37%);
}
.promote-me-btn .plus-icon{
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 14px;
    margin-left: 30px;
}
.promote-me-btn{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.promote-me{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;
}
.line-promote{
    border: 2px solid #917FFF;
}

.promote-title{
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.rented-caravan{
    display: flex;
}
.rented-caravan-content{
    width: 100%;
    padding-right: 5%;
    margin-top: 20px;
    margin-left: 15px;
}
.rented-caravan-card{
    margin-top: 25px;
    background: #FFFFFF;
    box-shadow: 0px 1px 6px 2px rgba(0, 0, 0, 0.07);
    border-radius: 18px;
    display: flex;
    align-items: center;
    padding: 15px;
    width: 100%;
}
.rent-content{
    margin-top: 10px;
    margin-left: 50px;
    width: 100%;
}
.rent-content h3{
    margin-bottom: 15px;
}
.rented-date, .rented-date span{
    color: #000;
    font-weight: 500;
    display: flex;
    align-items: center;
    padding: 15px 0;
}
.rented-caravan-card .caravan-details{
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 25px;
    margin-left: 0;
}
.caravan-details span{
    display: flex;
    align-items: center;
}
.rented-date p{
    margin-left: 5px;
    color: #196CA5;
}
.date-icon{
    margin-right: 5px;
    color: #C9C9C9;
    margin-bottom: 3px;
}
.caravan-details span{
    color: #117AA7;
    font-weight: 500;
}
.rented-caravan-content #button-1, #button-2{
    padding: 15px;
    border-radius: 30px;
    margin: 10px;
}
button#button-1{
    color: #FFF;
    background: linear-gradient(94.76deg, #117AA7 9.47%, #0DA2C9 73.37%);
}
button#button-2{
    border: 1px solid #EEEE;
    color: #196CA5;
    background-color: transparent;
}


@media only screen and (max-width: 1000px) {
    .rent-content, .caravan-details{margin: 0 !important; text-align: left !important;}
    .rented-caravan-card{width: 80%; margin: 0;}
    .rent-content .caravan-details, .rent-content .rented-date{flex-direction: column;}
}
.karavan-reserve-comp{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 15%;
    width: 100%;
}
.karavan-reserve-left{
    margin-right: 50px;
    margin-top: 50px;
    background-color: #FFF;
    width: 100%;
}
.karavan-reserve-left span#title{
    color: #1E98C7;
    font-weight: 600;
    font-size: 23px;
}
.karavan-reserve-left h1{
    font-size: 3rem;
    margin-bottom: 45px;
}
.karavan-reserve-left textarea{
    background: #F8F8F8;
    border-radius: 24px;
    resize: none;
    height: 120px;
}
.karavan-reserve-left button#devam{
    width: 100%;
    background: linear-gradient(94.76deg, #117AA7 9.47%, #0DA2C9 73.37%);
    border-radius: 60px;
    color: #FFF;
    padding: 10px;
    font-size: 1.2rem;
}

.karavan-reserve-right{
    padding-left: 35px;
    padding-right: 15%;
    width: 100%;
    padding-top: 100px;
    background-color: #FBFBFB;
}
.karavan-reserve-right h3{
    margin-top: 45px;
    margin-bottom: 25px;
    font-size: 1.7rem;
}
.reserve-icons{
    display: flex;
    flex-direction: column;
}
.reserve-icons span{
    margin: 5px;
    display: flex;
    align-items: center;
    color: #196CA5;
}
.karavan-reserve-right hr{
    margin: 25px;
    width: 100%;
}
span#price{
    font-weight: 500;
    color: #196CA5;
    margin: 15px;
    display: flex;
    align-items: center;
}
span#price, #icon-price{
    font-size: 3rem;
}


/* Success Message */
.success-message{
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.success-message p{
    color: #A4A4A4;
}
.success-message button{
    background: linear-gradient(94.76deg, #117AA7 9.47%, #0DA2C9 73.37%);
    border-radius: 60px;
    padding: 10px;
    width: 20%;
    color: #FFF;
    font-size: 1.2rem;
    margin-top: 45px;
}

@media only screen and (max-width: 1000px){
    .karavan-reserve-comp{
        flex-direction: column-reverse;
        padding: 0;
    }
    .karavan-reserve-right{
        padding: 0 25px;
    }
    .karavan-reserve-left{
        padding: 0 15px;
    }
}
.ek-urunler-comp{
    padding: 1% 15%;
    position: relative;
    display: flex;
    justify-content: center;
}
.ek-urunler-comp button#geri{
    display: flex;
    align-items: center;
    justify-content: space-between;
    top: 0;
    left: 0;
    margin: 35px 180px;
    position: absolute;
    padding: 10px 20px;
    color: #FFF;
    font-size: 1.3rem;
    background: linear-gradient(94.76deg, #117AA7 9.47%, #0DA2C9 73.37%);
    border-radius: 60px;
}
.ek-urunler-comp img{
    margin-top: 20px;
}
.ek-urunler-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #FBFBFB;
    margin-top: 40px;
    padding: 75px;
}
.ek-urunler-content h1{
    margin-bottom: 35px;
}
.urun-card{
    display: flex;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.06);
    border-radius: 24px;
    max-width: 490px;
    margin: 15px;
}
.urun-card img{
    margin: 10px;
}
span#urun-desc{
    font-weight: 500;
    font-size: 13px;
}
span#title{
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #000000;
}
span#urun-price{
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #1E98C7;
}
.urun-detay{
    margin-left: 15px;
    margin-right: 15px;
    padding: 30px 0;
}
.urun-img{
    background-color: #F9F9F9;
}
.urun-title{
    display: flex;
    flex-direction: column;
}
.ek-urunler-content hr{
    border: 1px solid #F6F6F6;
    margin: 15px 0;
}
.quantity{
    background: #1E98C7;
    border-radius: 60px;
    width: 100px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}
button#white{
    cursor: pointer;
    margin: 0 8px;
    padding: 5px 11px;
    background-color: #FFF;
    color: #1E98C7;
    border-radius: 100%;
}
span#number{
    color: #FFF;
    font-weight: 600;
}
.urun-cards{
    padding: 0 5%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.price-banner{
    background: #F4F4F4;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 45px;
}
.price-banner h1{
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
}
.price-banner span{
    margin-left: 15px;
}
.price-banner button{
    margin-left: 20px;
    padding: 10px 15px;
    background: linear-gradient(94.76deg, #117AA7 9.47%, #0DA2C9 73.37%);
    border-radius: 60px;
    color: #FFF;
    font-weight: 500;
}

@media only screen and (max-width: 1000px) {
    .urun-cards{
        flex-direction: column;
        flex-wrap: wrap;
    }
    .ek-urunler-content{
        padding: 0;
    }
    .ek-urunler-comp img{
        margin-top: 85px;
    }
    button#geri{
        margin-top: 10;
        margin-left: 10;
        margin: 0 !important;
    }
    .urun-card{
        flex-direction: column;
    }
}
.pay-inputs{
    display: flex;
    flex-direction: column;
}
.pay-inputs input{
    background-color: #F8F8F8;
    color: #929292;
    padding: 15px;
    width: 700px;
    border-radius: 30px;
    margin: 15px;
}
.tutar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    margin: 20px 0;
}
span#toplam{
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}
span#toplam-fiyat{
    font-weight: 500;
    font-size: 26px;
    line-height: 39px;
    color: #1F98C7;
}
button#odeme-btn{
    margin-top: 15px;
    color: #FFF;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    width: 100%;
    padding: 15px;
    background: linear-gradient(94.76deg, #117AA7 9.47%, #0DA2C9 73.37%);
    border-radius: 60px;
}
@media only screen and (max-width: 1000px) {
    .payment-inputs{
        padding: 0 10px;
        width: 100%;
    }
    .success-message{
        margin: 0;
        margin-top: 35px;
        margin-left: 40px;
        width: 80%;
    }
    .success-message p{
        text-align: center;
    }
}
.update-panels input{
    padding: 35px;
    background-color: #FFF;
    margin: 35px;
    border-radius: 15px;
    width: 100%;
}
.update-panels{
    display: flex;
    flex-direction: column;
    padding: 5%;


}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    list-style: none;
    border: 0;
    outline: 0;
    font-family: 'Poppins', sans-serif;
}

