.footer{
    height: 600px;
    width: 100%;
    position: relative;
    background: url(./assets/bg.svg) no-repeat;
    background-size: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 30px 10%;
    padding-top: 230px;
}

.footer img#icon{
    cursor: pointer;
    width: 75px;
    right: 45px;
    top: 45px;
    position: absolute;
}
.social-media span{
    cursor: pointer;
    margin-right: 10px;
    margin-top: 20px;
    height: 32px;
    width: 32px;
    text-align: center;
    padding: 4px;
    color: #0B91C5;
    border:1px solid #E9E9E9;
    border-radius: 50%;
    display: inline-block;
}
hr{
    height: 2px;
    background-color: #000;
}
.parts{

    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.part img#logo{
    cursor: pointer;
}
.part h4{
    color: #196CA5;
    margin-bottom: 15px;
}
.part ul li{
    cursor: pointer;
    margin-bottom: 18px;
    
    color: #196da594;
    font-weight: 500;
    display: flex;
    align-items: center;
    font-size: .9vw;
}
.copyright-text{
    color: #196CA5;
    font-weight: 500;
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.copyright-text p{
    font-size: .9vw;
}
.terms-conditions ul{
    width: 100%;
    display: flex;
    justify-content: space-around;
}
.terms-conditions ul li{
    margin: 0 10px;
    cursor: pointer;
    font-size: .8vw;
}
.plus-icon-footer, ul#mob-version{display: none;}
@media only screen and (max-width: 1000px) {
    .footer{
        height: 700px;
      
    }

    .parts{
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
    }
    .part h4{
        font-size: 7vw;
        margin-top: 25px;
    }
    .part ul li{
        font-size: 4vw;
    }
    .copyright-text{
        flex-direction: column;
        text-align: center;
    }
    .copyright-text p{
        margin-bottom: 25px;
        text-align: center;
    }
    .copyright-text p, .copyright-text .terms-conditions ul li{
        font-size: 2.5vw;
    }
    .part{
        width: 100% !important;
    }
    .f-mb-footer{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .closed-mb ul{
        display: none;
    }
    .plus-icon-footer{
        font-size: 1.5rem;
        margin-top: 10px;
        color: #0B91C5;
        font-weight: 800;
        display: block;
    }
    ul#mob-version{
        display: block;
    }
} 