.blog{
    text-align: center;
}
.blog h1:nth-child(1){
    color: #2689B2;
}
.blog h1:nth-child(2){
    color: #196CA5;
}
.blog h1{
    font-size: 50px;
    line-height: 120.5%;
}
.blog-cards{
    width: 100%;
    margin-top: 35px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}


.mob-blog-sugg{
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    
}
.blog_link{
    margin: 15px !important;
}
@media only screen and (max-width: 1000px) {
    .mob-blog-sugg{
        flex-wrap: wrap;
        padding: 0px !important;
    }
    .blog_link{
        margin: 15px 0 !important;
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .blog_link p{
        max-width: 250px;
    }
} 


