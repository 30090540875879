/* NAVBAR */
.navbar-white{
    background-color: #FFF;
    display: flex;
    align-items: center !important;
    justify-content: space-between;
    box-shadow: 0px 4px 23px rgba(0, 0, 0, 0.1);
    padding: 20px 8%;
}

.left-menu-white{
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.left-menu-white ul{
    display: flex;
}

.left-menu-white ul li{
    font-size: 0.9vw;
    cursor: pointer;
    margin-left: 25px;
    list-style-type: none;
    font-weight: 500;
}
.after-logged, .before-logged{
    display: flex;
}

.hamburger-menu{
    display: none;
}
@media only screen and (max-width: 1000px) {
    .left-menu-white ul{
        display: none;
    }
    .right-menu-white .after-logged,.right-menu-white .before-logged{
        display: none;
    }
    .hamburger-menu{
        display: block;
    }
    .navbar-white{
        flex-direction: row-reverse;
    }
    .navbar-white img.logoCaravinn{
        left: 32%;
        top: 15px;
        width: 40%;
        position: absolute;
    }
}