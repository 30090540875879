#google-map{
    width: 600px;
    height: 400px;
}

@media only screen and (max-width: 1000px) {
    #google-map{
        width: 300px;
        height: 500px;
    }
}