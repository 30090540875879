.admin-left-menu{
    background-color: #FFF;
    color: #FFF;
    height: 150vh;
    padding: 2% 0;
    min-width: 15%;
}
.admin-page-content{
    display: flex;
    justify-content: space-between;
    padding: 20px;
  
}
.admin-left-menu ul li{
    padding: 20px 30px;  
    transition: 0.8s;
}
.admin-left-menu ul li:hover{
    background-color: #c5dce5; 
    transition: 0.8s;
}
.admin-left-menu ul .link{
    color: #0B91C5;
    font-weight: 600;
}
.input-data textarea{
    width: 100%;
    border-radius: 0;
}
.input-data{
    margin: 15px;
    display: flex;
    align-items: center;
}
select#select-filter{
    width: 25%;
    margin-left: 30px;
    height: 50px;
    text-align: center;
}
.input-data input, select{
    height: 50px;
    width: 100%;
    padding: 15px;
}
.input-data label{
    width: 150px;
    margin-right: 15px;
}
.admin-page-content button#insert{
    width: 350px;
    padding: 15px;
    margin-top: 10px;
    background-color: #FFF;
    color: #000;
    font-weight: 600;
}
.insert{
    width: 50%;
    display: flex;
    flex-direction: column;
}
.table{
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    width: 80%;
    padding-right: 15px;
}
.table input{
    width: 100%;
    padding: 10px;
    font-size: 1.4rem;
    margin-bottom: 20px;
}
table {
    background-color: #FFF;
    padding: 35px;
    border-radius: 15px;
} 
.is tr th{
    padding: 15;
}
th{
    border-bottom: 2px solid #E6F1F6;
    padding: 20px;
}
td {
    padding: 24px;
    text-align: center;
}
td#blog_body{
    max-width: 400px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}