.profile-header{
    background-color: #1E98C7;
    display: flex;
    align-items: center;
    padding-left: 10%;
    color: #F5f5f5;
    height: 90px;
}
.profile-header h1{
    font-weight: 600;
}