
.about-wrapper-main{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.circle-cards{
    display: flex;
    position: absolute;
    bottom: 230px;
    flex-wrap: wrap;
}
.circle-card{
    box-shadow: 0px 4px 36px 3px rgba(155, 208, 255, 0.3);
    border-radius: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 25px;
    width: 265px;
    height: 265px;
    background-color: #FFF;
}
.circle-card img{
    margin: 15px;
}
.circle-card h4{
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    color: #000000;
}
.circle-card span{
    color: #1E98C7;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;

}
.about-wrapper-content{
    margin-top: 100px;
    padding: 2% 0;
    text-align: center;
}
.about-wrapper-content h1{
    margin-bottom: 30px;
}
.about-wrapper-content p{
    max-width: 800px;
    color: #848484;
}

@media only screen and (max-width: 1000px) {
    .circle-cards{
        display: none;
    }
    .about-wrapper-content{
        padding: 0 20px;
    }
    .about-wrapper-main{
        height: 500px;
    }
}