.bl-bg{
    background: no-repeat linear-gradient(180.3deg, #006E9D 0.26%, #BBEBFF 79%);
}
.blog-details-header-main{
    
    background: url('./bg.svg') no-repeat;
    color: #FFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}   

.blog-details-header-main h3{
    margin: 50px 0;
    display: flex;
    align-items: center;
    font-weight: 600;
}

.blog-details-header-main span{
    margin-bottom: 30px;
}

img.blog-details-header-img{
    z-index: 8;
    width: auto;
    border-radius: 35px;
    max-height: 60vh;
}
.blog-details-header-div{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 75%;
    height: 600px;
    background-color: transparent;
    z-index: 10;
}


@media only screen and (max-width: 1000px) {
    .blog-details-header-main h1{
        text-align: center;
        font-size: 7vw;
        max-width: 79vw !important;
    }
    .blog-details-header-main{
        background-image: none  ;
    }
    .blog-details-wrapper h1{
        max-width: 350px !important;
        font-size: 7vw;
    }
    .login-signup-comp{
        flex-direction: column;
    }
     .blog-details-header-div{
        height: 450px;
    }
    img.blog-details-header-img{
        width: 80vh;
        position: relative;
        z-index: 1 !important;
    }
}