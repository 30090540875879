.advantages{
    height: 1000px;
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 9%;
    
}
.advantages-text{
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.advantages-img-header{
    margin-bottom: 150px;
    width: 75%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.advantages-img-header h1{
    max-width: 700px;
    font-style: normal;
    font-weight: 600;
    font-size: 3vw;
    line-height: 5vw;
    margin-left: 35px;
}

.advantages .flex{
    width: 40%;
    display: flex;
}

    





@media only screen and (max-width: 1000px) {
    .advantages{
        max-width: 100%;
        padding: 0;
        height: 1400px;
        flex-direction: column;
        margin: 0;
    }
    .advantages-img-header{
        flex-direction: column;
        margin: 0;
    }
    .advantages-img-header img{
        width: 50%;
        margin-bottom: 25px;
    }
    .advantages-img-header h1{
        font-size: 6vw;
        line-height: 35px;
    }
    .advantages-text .flex .flex-2, .advantages-text{
        flex-direction: column;
    }
    .advantages-text .flex .flex-2 p{
        text-align: center;
        min-width: 100%;
    }
    .advantages-text{
        max-width: 100% !important;
        justify-content: center;
        align-items: center;
    }
    .mb{
        margin-top: 35px;
    }
    

    .mb{
        display: none;
    }
}