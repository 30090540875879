*{
    font-family: 'Poppins', sans-serif;
}

.wrapper{

    position: relative;
    padding: 3% 7%;
    border-radius: 36px !important;
    background-color: #fff;
    width: 100%;
}


html{
    max-width: 100vw !important;
}
@media only screen and (max-width: 1550px) {
    .left-menu ul li{
        font-size: smaller;
        margin-left: 15px;
    }
    .navbar .left-menu img{
        width: 95px;
    } 
    
.wrapper{

  z-index: 1;
}
} 
