.blue-section{
    background-color: #21A7DB;
    width: 100%;
    padding: 30px 7%;
    padding-left: 12%;
}

img.blue-section-img{
    width: 80%;
}

.blue-section .flex{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    color: #FFF;
}

@media only screen and (max-width: 1000px) {
    .blue-section{
        padding: 50px;
    }
    .blue-section .flex{
        align-items: center;
        justify-content: center;
        flex-direction: column-reverse;
    }
    .blue-section .flex .flex-1, .blue-section .flex .flex-2{
        text-align: center;
    }
}
