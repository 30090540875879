.card{
    position: relative;
    box-shadow: -5px 5px 19px 5px rgba(0, 0, 0, 0.09);
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    margin: 8px;
    height: 100%;
    width: 350px;
}
.card img{
   margin: 7px;
   object-fit: cover;
}
.card h3{
    padding: 20px;
}
span#line-hr{
    border: 1px solid #EBEBEB ;
    width: 90%;
}
.location{
    margin-left: 10px;
    display: flex;
    align-items: center;
    padding: 15px;
    color: #196CA5;
    font-weight: 500;
}
.location i{
    margin-right: 10px;
    color: #C5C5C5;
}
#caravan-card-mobile img.cardImg{
    height: 220px;
    width: 330px;
    border-radius: 15px;
    object-fit: cover;
}
.icons{
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding: 25px 10px;
}
.icon{
    display: flex;
    align-items: center;
    color: #196CA5;
    font-weight: 500;
}
.icon img{
    margin-right: 15px;
}
.card hr{
    width: 89%;
    margin: auto;
    height: 1px;
    border: 0;
    background-color: #EBEBEB;
}

.like-button {
    position: absolute;
    z-index: 2;
    top: 25px;
    right: 29px;
    color: red;
}
.promoted{
    position: absolute;
}
.mCards{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
@media only screen and (max-width: 1000px) {
  .card{
        width: 200px;
        display: inline-block;
    }
    
    .card-test{
        margin: 10px 1px;
    }
    .icons{
        display: none !important;
    }
    .card img{
        margin: 0px;
    }
    #caravan-card-mobile img.cardImg{
        height: 108px;
        width: 90%;
        border-radius: 15px;
    } 
    .wrapper    {
        padding: 0;
    }
    .mCards{
        align-items: stretch;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        justify-content: flex-start;
    }



}