.our-team{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 80px 0;
}
.our-team h1{
    margin-bottom: 35px;
}
.our-team-cards{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 80vw;
    justify-content: center;
}
.our-team-card{
    margin: 20px;
    padding: 30px;
    width: 300px;
    border-radius: 30px;
    border: 2px solid #EEF1F3; 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.our-team-card span{
    text-align: center;
}
.our-team-card img{
    margin-bottom: 20px;
}
@media only screen and (max-width: 1000px) {
    .our-team-cards{
        flex-direction: column;
        align-items: center;
        width: 100vw;
        justify-content: center;
    }
    .our-team-card{
        margin-bottom: 20px;
    }
}