.info-section-text h2{
    max-width: 600px;
    font-size: 1.6vw;
    margin-bottom: 20px;
}

.info-section-text p{
    max-width: 580px;
    color: #848484;
}
.info-section-main{
    background-color: #FFF;
    height: 700px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 10%;
}
.info-section-2-main{
    margin-top: 120px;
    background-color: #F7F9FB;
    height: 700px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 10%;
}

@media only screen and (max-width: 1000px) {
    .info-section-2-main, .info-section-main{
        flex-direction: column;
        height: 500px;
        margin-top: 200px;
    }
    .info-section-text h2{
        font-size: 5vw;
    }
}