.background, .text{
    position: relative;
    z-index: 0;
}

.main{
    height: auto;
}
.left-plant, .circle-bg, .cloud, .right-plant, .playButton{
    position: absolute;
}
img.caravan{
    width: 100%;
    height: auto;
}

.text-group{
    width: 479px;
}

.left-plant{
    top: 115px;
    left: 10%;
}


.cloud{
    top: 85px;
    left: 55%;
}

.right-plant{
    top: 290px;
    left: 85%;
}


/* 
.playButton{
    left: 30%;
    top: 385px;
} */


#title-1{
    line-height: 120.5%;
    font-size: 4vw;
    font-weight: 500;
    color: #127BA8;
}

#title-2{
    font-size: 2vw;
    font-weight: 500;
    color: #127BA8;
}

.text-group p{
    font-weight: 400;
    font-size: 19px;
}

.group{
    position: relative;
    z-index: 1;
    padding: 90px 10%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media only screen and (max-width: 1100px) {
.group{
    flex-direction: column;
    padding: 30px 10%;
} 

.group .text-group{
    margin-top: 45px;
    width: 600px;   
}
img.cloud{
    display: none;
}
 .group .text{
    text-align: left;
    width: 100%;
 }

 .group .text h1#title-1{
    font-size: 8vw;
 }
 .group .text h1#title-2{
    font-size: 7vw;
    margin-bottom: 10px;
 }
 .group .text p{
    font-size: 2vw;
 }
}

@media only screen and (max-width: 1000px) {
    .group .text p{
        font-size: 4vw;
        width: 60vw !important;
    }
    .main .background{
        display: none !important;
    }
}
