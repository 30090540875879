.blog-main-page{
    background: #FFF;
    height: 100%;
}

.blog-main-page-content{
    width: 100%;
    height: 100%;
    background-color: #FFF;
    margin-top: 75px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.blog-main-page-content h1{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 2.3vw;
    font-weight: 600;
}
.blog-main-page-content img.blog-main-bg{
    position: absolute;
    width: 100%;
}
@media only screen and (max-width: 1000px) {
    .blog-main-page-content h1 span{
        font-size: 12vw;
    }
    .blog-main-page-content h1{
        margin-bottom: 50px;
    }
    .blog-main-page-card-header{
        display: none;
    }
    .btn.btn-blue.adsss{
        font-size: 4vw;
        padding: 10px;
    }

}