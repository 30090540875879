.home-header{
    background: no-repeat linear-gradient(180.3deg, #006E9D 0.26%, #BBEBFF 79%);
    height: 600px;
    position: relative;
}


.title-sec{
    width: 100%;
    position: relative;
    z-index: 2;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #FFF;
    font-size: x-large;
    padding: 7rem;
    
}
.te{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 4;
}
.title-sec h1{
    animation: fade-down 0.7s;
}
img.title-sec-bg{
    padding:0 80px;
    width: 100%;
    position: absolute;
    bottom: 2px;
}
.button3, .button4{
    width: 230px;
}
.button3{
    background-color: #FFF;
    color: #006E9D;
    font-weight: 500;
}
.button4{
    background-color: transparent;
    color: #fff;
    font-weight: 500;
}
.buttons{
    margin-top: 32px;
    display: flex;
}
.divv{
    position: relative;
    z-index: 4;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 55%;
}
.searchBar{
    margin-top: 40px;
    background: #FFFFFF;
    box-shadow: 0px 4px 37px rgba(13, 160, 201, 0.54);
    border-radius: 100px;
    display: flex;
    align-items: center;
    z-index: 10;
    height: 76px;
    padding-left: 40px;
}
.searchBar input{
    background-color: #FFF;
    border: none;
    font-size: 0.8vw;
    outline: none;
}
input#mainInput{
    width: 40%;
}
.searchBar input:active{
    border: none;
    outline: none;
}
.button5{
    background: linear-gradient(94.76deg, #117AA7 9.47%, #0DA2C9 73.37%);
    border-radius: 110px !important;
    color: #FFFFFF;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    height: 65px !important; 
    width: 140px;
    margin-right: 5px;
}

.dateInput{
    display: flex;
    align-items: center;
    margin-left: 20px;
    max-width: 170px;
}
    
.dateInput img{
    margin-right: 15px;
}
button{
    cursor: pointer;
}
.vinn{
    margin-left: 15px;
}
.vinn button{
    height: 65px;
    margin-right: 5px !important;
}

span#line{
    border: 1px solid #F0F0F0;
    height: 70%;
    margin-left: 10px;
}
/* img.title-sec-bg-mob{
    display: none;
 } */
 img.title-sec-bg-mob{
    width: 60%;
    position: absolute;
    bottom: -70px;
    left: 0;
    display: none;
}
img.title-sec-bg-mob-cloud{
    width: 20%;
    position: absolute;
    top: 10px;
    left: 20px;
    display: none;
}
.inputs-mobile{
    display: none !important; 
}
img.title-sec-bg1{
    display: none;
}
@media only screen and (max-width: 500px) {
 .home-header .title-sec .te h1{
    font-size: 9vw;
 }
.title-sec{
    padding: 3rem 0;
}
 .title-sec .buttons button{
    font-size: 4vw;
    width: 70%;
    margin-bottom: 10px;
 }
 .home-header{
    height: 750px;
 }
 
 .title-sec .buttons{
    flex-direction: column-reverse;
 }
 .vinn{
    margin-left: 5px;
}
img.title-sec-bg1{
    z-index: -3;
    display: block;
    position: absolute;
}
img.title-sec-bg1.down{
    bottom: 0vh;
}
 .searchBar{ 
    padding: 0;
    height: auto;
    background-color: transparent;
    box-shadow: 0px 0px 0px transparent !important;
    flex-direction: column;
}
.searchBar .vinn button{
    height: auto;
    font-size: 6vw;
    width: 80vw;
    border: 0px;
    margin-bottom: 10px;
}
 .dateInput img{
    width: 15% !important;
    margin: 0;
 }
 .mbo{
    background-color: #FFF;
    border-radius: 80px;
    margin: 15px 35px;
 }
 .dateInput{
    margin: 5px;
    background-color: #FFF;
    border-radius: 60px;
 }
 .dateInput input, input#mainInput{
    font-size: 3.5vw;
    border-radius: 80px;
    padding: 15px;
    width: 80vw;
 }
 .mbo{
    display: flex;
 }
 img.title-sec-bg{
    display: none;
 }
 img.title-sec-bg-mob, img.title-sec-bg-mob-cloud{
    display: block;
 }
 img.title-sec-bg-mob-cloud{
    width: 15%;
 }
 img.title-sec-bg-mob{
    width: 40%;
    bottom: -30px;
}
.inputs-mobile{
    display: block !important;
}
.inputs-desktop{
    display: none !important;
}
img.title-sec-bg1.down{
    display: block;
}
.searchBar input{
    font-size: 3vw;
}
.DatePicker{
    border-radius: 0px !important;
}
.mob-input{
    padding: 5px;
    background-color: #fff;
    border-radius: 20px;
    padding-bottom: 5px;
}
.te h1{
    width: 60%;
}
} 
/* @media only screen and (max-width: 500px) {
    img.title-sec-bg-mob{
        width: 50%;
        bottom: -70px;
    }
} */

@media only screen and (min-width: 1000px) {
    img.title-sec-bg1.down{
        display: none;
    }
    select#select-cont{
        width: 100%;
    }
}