/* NAVBAR */
.navbar{
    display: flex;
    align-items: center !important;
    justify-content: space-between;
    padding: 20px 8%;
    background-color: transparent;
}

.left-menu{
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.left-menu ul{
    display: flex;
}

.left-menu ul li{
    font-size: 0.9vw;
    cursor: pointer;
    margin-left: 25px;
    list-style-type: none;
    font-weight: 500;
}

.hamburger-menu{
    display: none;
}

.right-menu{
    display: flex;
}

.right-menu button, .title-sec button{
    border: 2px solid #FFFFFF;
    border-radius: 30px;
    
}
.navbar img.logoCaravinn, .navbar-white img.logoCaravinn{
    width: 170px;
}
@media only screen and (max-width: 1000px) {
    .left-menu ul{
        display: none;
    }
    .right-menu .after-logged,.right-menu .before-logged{
        display: none;
    }
    .hamburger-menu{
        display: block;
    }
    .navbar{
        flex-direction: row-reverse;
    }
    .navbar img.logoCaravinn{
        left: 30vw;
        top: 17px;
        width: 40%;
        position: absolute;
    }
}












.app__navbar-smallscreen_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 90%;
    height: 100vh;
    background: #FFF;
   
    transition: 2s;

    flex-direction: column;
    z-index: 20;
}

.app__navbar-smallscreen_overlay .overlay__close {
    font-size: 27px;
    color: #0b90c5;
    cursor: pointer;

    position:absolute;
    top: 20px;
    right: 30px;
}
.fl-ham{    display: flex;
    justify-content: space-between;}
.app__navbar-smallscreen_links ul hr{
    border: 1px solid #F2F2F2;
}
.app__navbar-smallscreen_links ul li {
    margin: 2rem;
    cursor: pointer;
    color: #0b90c5;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000000;

}
.loginButton, .karavanAddButton{
    display: flex;
    justify-content: center;
    
}
.karavanAddButton{
    align-items: center;
}
.loginButton{
    height: 250px;
}
.loginButton button{
    margin-top: 50px;
    color: #1E98C7;
    background-color: transparent;
    border: 1px solid #EAEAEA;
}
.karavanAddButton button{
    background: linear-gradient(94.76deg, #117AA7 9.47%, #0DA2C9 73.37%);
    color: #FFF;
}
.loginButton button, .karavanAddButton button{
    padding: 10px 30px;
    width: 200px;
    border-radius: 60px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
}
.app__navbar-smallscreen_links ul li:hover {
    color: var(--color-black);
}
.app__navbar-smallscreen_links {
    list-style: none;
    margin-top: 70px;
}





