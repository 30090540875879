
.karavanKirala img#img{
    position: absolute;
    z-index: 0;
}
.left-menu img#mockup{
    width: 80%;
    height: auto;
}
.karavanKirala .content{
    padding: 120px 150px;
    background:no-repeat url(./assets/background.svg);
}
.content .right-menu{
    display: flex;
    flex-direction: column;
    color: #FFF;
}


.karavanKirala .right-menu h1{
    font-size: 3vw;
    font-weight: 700;
    margin-bottom: 15px;
    width: 100%;
}
.karavanKirala .item{
    display: flex;
    align-items: center;
    margin: 15px;
    margin-left: 0;
}
span#circle{
    border: 1px solid #FFF;
    width: 10px;
    height: 10px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    border-radius: 100%;    
    margin: 10px;
    margin-left: 0;
}
.text h3{
    font-weight: 500;
}
.text p{
    font-size: .9vw;
}

.ed{
    margin-top: 15px;
    margin-right: 0;
}

@media only screen and (max-width: 1000px) {
    .karavanKirala .content{
        width: 100% !important;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0;
        padding-top: 120px;
        padding-bottom: 30px;
    }
    .karavanKirala .content .left-menu, .karavanKirala .content .right-menu{
        align-items: center;
        justify-content: center;
    }
    .karavanKirala .content .left-menu img#mockup{
        width: 50% !important;
    }
    .karavanKirala .content .right-menu h1{
        text-align: center;
        font-size: 5vw;
        margin-top: 25px;
    }
    .karavanKirala .content .right-menu .item .text h3{
        font-size: 4.4vw;
    }
    .karavanKirala .content .right-menu .item .text p{
        font-size: 3vw;
        width: 75% !important;
    }
    .karavanKirala .content .right-menu .item{
        margin: 25px !important;
    }
    .karavanKirala .content .right-menu button{
        margin: 0 !important;
        font-size: 3.5vw;
        padding: 5px 30px;
    }
}