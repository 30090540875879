.giveRent{
    position: relative;
    width: 100%;
    height: 720px;
}
.giveRent img#imgg{
    position: absolute;
    width: 100%;
    top: -100px;
    z-index: 0;
}

.giveRent .content{
    width: 100%;
    display: flex;
    justify-content: space-around;
    position: absolute;
    padding: 60px 8%;
}
.giveRent .left-menu h1:nth-child(1){
    color: #2689B2;
}
.giveRent .left-menu h1:nth-child(2){
    color: #196CA5;
}
.giveRent .left-menu h1{
    font-size: 3vw;
    line-height: 120.5%;
}
.giveRent .left-menu .item{
    margin-top: 35px;
}
.giveRent .right-menu{
    display: grid;
    place-items: center;
}
.giveRent .right-menu img{
    width: 80%;
    height: auto;
}
.giveRent .item{
    display: flex;
    align-items: center;
}
.giveRent span#circle{
    border: 1px solid #196da554;
    color: #196CA5;
    width: 10px;
    height: 10px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    border-radius: 100%;    
    margin: 10px;
}
.giveRent .item{
    display: flex;
    align-items: center;
}
.giveRent .text h3{
    font-weight: 500;
    color: #196CA5;
}
.giveRent .text p{
    font-size: 1vw;
    color: #3D434C;
}

@media only screen and (max-width: 1000px) {
    .giveRent{
         height: 1000px;
    }
    .giveRent .right-menu img{
    width: 100%;
    height: auto;
}
   .giveRent .content h1{
    font-size: 7vw;
    width: 100% !important;
    text-align: start;
   }
   .giveRent .left-menu{
      margin-top: 35px;
   }
   .giveRent .content{
    flex-direction: column-reverse !important;
   
   }
   .giveRent .content .left-menu .text p{
    font-size: 3vw;
   }
   .giveRent .content .buttons button{
    font-size: 4vw;
   }
   .giveRent .content .buttons{
    margin-bottom: 35px;
   }
}