
.edit-panel-content h1{
    margin-top: 70px;
}
.edit-panel-content{
    position: relative;
    padding-left: 25%;
    text-align: center;
    width: 100%;
}
.edit-panel-content button#geri{
    position: absolute;
    left: 0;
    margin: 20px;
    background: linear-gradient(94.76deg, #117AA7 9.47%, #0DA2C9 73.37%);
    border-radius: 60px;
    color: #FFF;
    padding: 10px 25px;
}
.edit-panel{
    display: flex;
    flex-direction: row;
    padding-right: 20%;
}

.edit-card{
    background: #FFFFFF;
    box-shadow: 0px 1px 6px 2px rgba(0, 0, 0, 0.07);
    border-radius: 18px;
    margin-top: 15px;
    display: flex;
    padding: 45px;
    align-items: center;
    flex-direction: column;
}
.edit-card img{
    width: 150px;
    margin-bottom: 20px;
}
.edit-card input{
    width: 100%;
    background: #F8F8F8;
    border-radius: 30px;
    padding: 10px;
    color: #000;
    margin: 10px;
}
.edit-card button{
    margin: 10px;
    background: linear-gradient(94.76deg, #117AA7 9.47%, #0DA2C9 73.37%);
    border-radius: 60px;
    color: #FFF;
    padding: 10px;
    width: 100%;
}
span#icon-flex{
    display: flex;
    width: 100%;
    align-items: center;
}
