.caravan-details-header, .caravan-images, .small-images{
    display: flex;
    align-items: center;
}
.caravan-images{
    width: 50vw;
    justify-content: center;
}
.small-images{
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
    max-height: 600px;
}
.main-image img{
    width: auto;
    height: 650px;
    object-fit: cover;
}
.small-images img{
    margin-bottom: 10px;
    height: 150px;
    object-fit: cover;
}
.slider-class{
        width: 40vw;
        height: 70vh;
        margin: 0 auto;
}
.caravan-details{
    margin-left: 45px;
    margin-top: 45px;
}
.caravan-feauters{
    width: 400px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}
.caravan-feauters span{
    margin: 5px 0;
    display: flex;
    align-items: center;
    color: #1E98C7;
    font-weight: 500;
}
.icon-caravan{
    margin-right: 10px;
    color: #C5C5C5;
}
.caravan-feauters button{
    margin-top: 15px;
    border: 1px solid #1E98C7;
    padding: 15px 10px;
    width: 70%;
    border-radius: 30px;
    font-weight: 600;
    font-size: 1rem;
}
.description hr{
    background-color: #F3F3F3;
    border: 1px;
    margin-top: 20px;
}
.description p{
    margin-top: 15px;
    width: 95%;
    margin-bottom: 50px;
}
.description button{
    margin-top: 15px;
    border: 1px solid #EEEEEE;
    padding: 15px 20px;
    border-radius: 30px;
    font-weight: 600;
    font-size: 1rem;
}
@media only screen and (max-width: 1000px){
    .caravan-details-header{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .caravan-details h1, .caravan-feauters span, .caravan-feauters{
        text-align: center;
        justify-content: center;
    }
    .caravan-feauters button{
        width: 100%;
    }
    .caravan-feauters{
        margin-bottom: 40px;
    }
    .main-image img{
        width: auto;
        height: 250px;
  
    }
    .small-images{
        flex-direction: row;
        width: 100%;
        display: none;
    }
    .small-images img{
        margin-top: 10px;
        border-radius: 0px !important;
        padding: 2px;
        height: 75px;
        
    }
    .caravan-images{
        flex-direction: column;
    }
    .slider-class{
        width: 80vw;
        height: 40vh;
        margin: 0 auto;
}
.caravan-details h1{
    margin-top: 25px;
}
}