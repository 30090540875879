
img.information-section-bg{
    position: absolute;
    width: 100%;
    z-index: 0;
}
.information-section .flex{
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
    padding: 9% 15%;
    
    color: #FFF;
}
.information-section .flex .flex-1{
    width: 60%;
}
.information-section .flex .flex-1 h1{
    font-size: 3vw;
    font-weight: 700;
    margin-bottom: 15px;
    width: 100%;
}
.information-section .flex-1 .item{
    display: flex;
    align-items: center;
    margin: 15px;
    margin-left: 0;
}
.information-section .flex-1 .item span#circle{
    border: 1px solid #FFF;
    width: 10px;
    height: 10px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    border-radius: 100%;    
    margin: 10px;
    margin-left: 0;
}
.information-section .text h3{
    font-weight: 500;
}
.information-section .text p{
    margin-top: 8px;
    font-size: .7vw;
}

.information-section .ed{
    margin-top: 5px;
    margin-right: 0;
}

img.information-hand-img{
    width: 100%;
}

  
@media only screen and (max-width: 1000px) {
    .information-section .flex{
        flex-direction: column-reverse;
        padding-top: 20px !important; 
        padding: 0;
        height: 100%;
        background-color: #0B91C5;
        align-items: center;
        justify-content: center;
    }
    .information-section .flex .flex-2{
        text-align: center;
    }
    .information-section .flex .flex-2 img{
        width: 50%;
    }
    .information-section .flex .flex-1 h1{
        font-size: 4vw;
        text-align: center;
        margin-top: 15px;
    }
    .information-section .flex .flex-1 .item .text p{
        font-size: 3vw;
    }
    .information-section .flex .flex-1{
        justify-content: center !important;
        align-items: center !important;
        width: 100%;
    }
    .information-section .flex .flex-1 .item{
        margin: 0;
        margin-bottom: 10px;
        width: 100%;
        justify-content: space-around;
        padding: 0 30px;
    }
    button.ed{
        font-size: 4vw;
        margin-left: 30%;
        margin-bottom: 5%;
    }
}