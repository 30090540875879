
@keyframes fade-right {
    0% {
      opacity: 0;
      transform: translateX(-3rem);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
}
@keyframes fade-left {
    0% {
      opacity: 0;
      transform: translateX(3rem);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
}
@keyframes fade-down {
    0% {
      opacity: 0;
      transform: translateY(-3rem);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
}
@keyframes fade-up {
    0% {
      opacity: 0;
      transform: translateY(3rem);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
}
