.blog_link{
    width: 370px;
    height: 330px;
    background: #FFFFFF;
    box-shadow: 0px 4px 28px -1px rgba(0, 0, 0, 0.17);
    border-radius: 18px;
    margin: 0 30px;
}
.blog_link img{
    border-radius: 15px;
    height: 230px;
    width: 370px !important;
    object-fit: cover;
}
.blog_link p{
    color: #000;
    margin: 15px;
    text-align: center !important;
}
.mob-blog-sugg{
    display: flex;
    justify-content: center;
    padding: 50px 150px;
    margin-bottom: 40px;
}
button.blog-all-btn{
    width: 250px;
    border: 2px solid #BFD4E5;
    border-radius: 30px;
    text-align: center;
    color: #196CA5;
    background-color: #FFF;
    margin-bottom: 150px;
    padding: 10px 20px;
    font-size: 1.2rem;
    font-weight: 600;
}