.buttons-2{
    padding: 0 5%;
    display: flex;
    justify-content: space-between;
    align-items: center; 
}
.cards{
    width: 100%;
    margin-top: 45px;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
}

.left-buttons, .right-buttons{
    display: flex;
}


button.btn-left,button.btn-right{
    color: #0DA0C9;
    border: 1px solid #EEEEEE;
    border-radius: 100%;
    background-color: transparent;
    width: 50px;
    height: 50px;
    margin-right: 10px;
    align-items: center;
}
.buttons-2 .left-buttons button{
    border-radius: 30px;
}

@media only screen and (max-width: 1000px) {
    .buttons-2{
        align-items: flex-start; 
    }
    .buttons-2 .left-buttons{
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
    }
    .buttons-2 .left-buttons button{
        font-size: .8rem;
        margin-bottom: 5px !important;
        width: 42%;
        margin: 10px !important;
    }
    .buttons-2 .right-buttons button{
        font-size: .7rem;
    }
    .left-buttons button, .right-buttons button{
        margin: 0 !important;
    }
    #caravan-card-mobile{
        padding-top: 10px;
    }
    .right-buttons{
        display: none;
    }
}


body, html {
    width: 100vw;
    min-width: 100vw;
    max-width: 100vw;
   
    overflow-x: hidden;
    overflow-y: auto;
}