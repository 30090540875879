.profile-panel{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 20%;
}

.menu{
    padding-left: 150px;
    padding-right: 100px;
    padding-top: 60px;
    background-color: #FFF;
    height: 100%;
    border-radius: 0 0 15px 0;
    padding-bottom: 100%;
}

.profile-info{
    position: relative;
    margin-top: 60px;
    background-color: #FFF;
    border-radius: 30px;
    height: 50vh;
    width: 100%;
    margin-left: 300px;
}
.grey-div{
    position: absolute; 
    background-color: #F9F9F9;
    border-radius:30px 30px 0 0;
    width: 100%;
    height: 150px;
}
.profile-info img{
    position: relative;
    margin-left: 45px;
    margin-top: 55px;
    height: 150px;
}
button.edit{
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 20px;
    margin-right: 25px;
    background-color: transparent;
    color: #0B91C5;
    font-weight: 600;
    font-size: 16px;
}

.user-information{
    position: absolute;
    bottom: 28%;
    left: 240px;
    display: flex;
    flex-direction: column;
}

.user-information h1{
    margin-bottom: 30px;
}
.user-information p{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    color: #000;
}
.user-icon{
    margin-right: 10px;
    color: #1E98C7;
}
button.change-password{
    position: absolute;
    bottom: 0;
    right: 0;
    margin-bottom: 20px;
    margin-right: 20px;
    padding: 10px 15px;
    border-radius: 30px;
    background-color: transparent;
    border: 2px solid #1E98C7;
    color: #0B91C5;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;
}


/* LEFT MENU */

.menu ul{
    display: flex;
    flex-direction: column;
}
.menu ul li{
    padding: 10px;
    font-size: 1.2rem;
}
.menu ul li span{
    display: flex;
    align-items: center;
}
.panel-icons{
    margin-right: 10px;
}



/* MODAL */
.title{
    font-weight: 600;
}
.change-password .error{
    width: 100%;
    text-align: center;
    margin-top: 10px;
}
.change-password{
    display: flex;
    flex-direction: column;
}
.change-password input{
    padding: 15px;
    border: 1px solid #EFEFEF;
    border-radius: 30px;
    color: #B5B5B5;
    margin: 10px 0;
}
.change-password input[type=button]{
    background: linear-gradient(94.76deg, #117AA7 9.47%, #0DA2C9 73.37%);
    border-radius: 60px;
    color: #FFF;
    cursor: pointer;
    margin-bottom: 0;
}
.exit span{
    position: absolute;
    right: 0;
    top: 0;
    margin-right: 20px;
    margin-top: 15px;
    cursor: pointer;
}

@media only screen and (max-width: 1000px){
    .profile-panel{
        flex-direction: column;
        padding: 0;
       
    }
    .profile-panel .menu{
        padding: 0;
    }
    .profile-info{
        border-radius: 0;
        height: 700px;
        margin: 0;
        flex-direction: column;
        align-items: center;
       padding: 0px 25px;
        justify-content: center;
    }
    .user-information, button.change-password, button.edit{
        position:static;
    }
    .grey-div{
        display: none;
    }
    .profile-info img{
        margin: 0;
        margin: 50px 0;
    }
}