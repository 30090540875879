
.our-values{
    padding: 120px;
    background: #F7F9FB;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.our-values h1{
    margin-bottom: 40px;
    color: #000;
}
.our-values p{
    margin-bottom: 40px;
    max-width: 900px;
    color: #848484;
}
.value-cards{
    display: flex;
    flex-direction: row;
}
.value-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    border: 2px solid #EEF1F3; 
    padding: 10px;
    margin: 10px 50px;
    width: 300px;
}
.value-card img{
    margin: 30px;
}
.value-card h1{
    margin-bottom: 10px;
}
.value-card p{
    max-width: 200px;
}
@media only screen and (max-width: 1000px) {
    .our-values{
        padding: 20px;
    }
    .value-cards{
        flex-direction: column;
    }
}