.rented-caravan{
    display: flex;
}
.rented-caravan-content{
    width: 100%;
    padding-right: 5%;
    margin-top: 20px;
    margin-left: 15px;
}
.rented-caravan-card{
    margin-top: 25px;
    background: #FFFFFF;
    box-shadow: 0px 1px 6px 2px rgba(0, 0, 0, 0.07);
    border-radius: 18px;
    display: flex;
    align-items: center;
    padding: 15px;
    width: 100%;
}
.rent-content{
    margin-top: 10px;
    margin-left: 50px;
    width: 100%;
}
.rent-content h3{
    margin-bottom: 15px;
}
.rented-date, .rented-date span{
    color: #000;
    font-weight: 500;
    display: flex;
    align-items: center;
    padding: 15px 0;
}
.rented-caravan-card .caravan-details{
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 25px;
    margin-left: 0;
}
.caravan-details span{
    display: flex;
    align-items: center;
}
.rented-date p{
    margin-left: 5px;
    color: #196CA5;
}
.date-icon{
    margin-right: 5px;
    color: #C9C9C9;
    margin-bottom: 3px;
}
.caravan-details span{
    color: #117AA7;
    font-weight: 500;
}
.rented-caravan-content #button-1, #button-2{
    padding: 15px;
    border-radius: 30px;
    margin: 10px;
}
button#button-1{
    color: #FFF;
    background: linear-gradient(94.76deg, #117AA7 9.47%, #0DA2C9 73.37%);
}
button#button-2{
    border: 1px solid #EEEE;
    color: #196CA5;
    background-color: transparent;
}


@media only screen and (max-width: 1000px) {
    .rent-content, .caravan-details{margin: 0 !important; text-align: left !important;}
    .rented-caravan-card{width: 80%; margin: 0;}
    .rent-content .caravan-details, .rent-content .rented-date{flex-direction: column;}
}