.caravans-header{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding-bottom: 85px;
    height: 50vh;
    background: no-repeat linear-gradient(180.3deg, #006E9D 0.26%, #BBEBFF 79%);
}
.caravans-header img{
    position: absolute;
    width: 73%;
}
.caravans-header h1{
    font-size: 3vw;
    color: #FFF;
}

@media only screen and (max-width: 1000px) {
    .caravans-header h1{
        font-size: 7vw;
        color: #FFF;
    }
    .caravans-header{
        height: 50vh;
        padding-bottom: 0;
    }
    
}
