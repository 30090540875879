



.edit-btn button{
    position: absolute;
    right: 0;
    margin-right: 15px;
    margin-top: 15px;
    padding: 10px 25px;
    border-radius: 10px;
    color: #1E98C7;
    font-weight: 600;
    background-color: #FFF;
}



/* ADVERT EDIT  */

.advert-edit button#geri{
    position: absolute;
    left: 0;
    margin: 20px;
    background: linear-gradient(94.76deg, #117AA7 9.47%, #0DA2C9 73.37%);
    border-radius: 60px;
    color: #FFF;
    padding: 10px 25px;
}   

.advert-edit{
    display: flex;

}

.advert-edit-content{
    width: 100%;
    padding-left: 20%;
    padding-right: 20%;
    position: relative;
    display: flex;
    
    flex-direction: column;
}
.advert-edit-content h1{
    text-align: center;
    margin-top: 55px;
    margin-bottom: 35px;
}
.advert-edit-card{
    position: relative;
    width: 100%;
    padding: 30px;
    background: #FFFFFF;
    box-shadow: 0px 1px 6px 2px rgba(0, 0, 0, 0.07);
    border-radius: 18px;
}
.advert-edit-card span#card-header{
    color: #929292;
}
.advert-edit-radio-btn{
    display: flex;
    margin: 20px 0;
}
.advert-edit-card .file-images{
    margin: 25px;
}
.advert-edit-content #aciklama{
    resize: none;
    height: 150px;
    border-radius: 20px;
}
.advert-edit-card h3{
    color: #171717;
    font-weight: 500;
    text-align: center;
    margin-top: 10px;
}
.advert-edit-radio-btn .radio-btn:first-child{
    margin-right: 15px;
}
.advert-edit-content button#kaydet{
    margin-top: 10px;
    width: 100%;
    background: linear-gradient(94.76deg, #117AA7 9.47%, #0DA2C9 73.37%);
    border-radius: 60px;
    padding: 10px;
    color: #FFF;
}
.date-calendar{
    background-color: #F8F8F8;
    padding: 15px;
    position: relative;
    margin: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

button#delete-button{
    position: absolute;
    right: 0;
    top: 0;
    color: #FFF;
    background-color: #0DA2C9;
    border-radius: 0 18px 0 18px;
    padding: 15px;
    font-size: 1.5rem;
}

/* PROMOTE BTN */
.promote-btn{
    position: absolute;
}
.promote-btn button{
    background: linear-gradient(94.18deg, #917FFF 12.67%, #63D1FF 76.54%);
    box-shadow: -5px 5px 19px 5px rgba(0, 0, 0, 0.09);
    border-radius: 24px;
    color: #FFF;
    padding: 10px 18px;
    margin-top: 15px;
    margin-left: 15px;
}
.promote-me button{
    background: linear-gradient(94.76deg, #63D0FF 9.47%, #907EFF 73.37%);
    border-radius: 60px;
    color: #FFF;
    width: 90%;
    padding: 15px;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
}
.promote-me-btn button{
    border-radius: 60px;
    color: #FFF;
    width: 90%;
    padding: 15px;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    background: linear-gradient(94.76deg, #117AA7 9.47%, #0DA2C9 73.37%);
}
.promote-me-btn .plus-icon{
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 14px;
    margin-left: 30px;
}
.promote-me-btn{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.promote-me{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;
}
.line-promote{
    border: 2px solid #917FFF;
}

.promote-title{
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}